import { render, staticRenderFns } from "./privacy-text_en.vue?vue&type=template&id=d1d8924e&"
import script from "./privacy-text_en.vue?vue&type=script&lang=js&"
export * from "./privacy-text_en.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports