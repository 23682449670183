import { render, staticRenderFns } from "./tac-text_en.vue?vue&type=template&id=5130bb61&"
import script from "./tac-text_en.vue?vue&type=script&lang=js&"
export * from "./tac-text_en.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports