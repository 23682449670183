<template>
  <b-col id="privacy-container" cols="12" class="content-row px-3 mb-3">
    <BackArrow @back="$emit('back')" />

    <b-row no-gutters>
      <component
        :is="localeText"
        @showBanner="$bvModal.show('cookie-modal')"
      /> </b-row
    ><CookieModal />
  </b-col>
</template>

<script>
import PrivacyTextDe from "@/components/legal/privacy-text_de.vue";
import PrivacyTextEn from "@/components/legal/privacy-text_en.vue";
import BackArrow from "@/components/legal/back-arrow.vue";
import CookieModal from "@/components/legal/cookie-modal.vue";
export default {
  name: "PrivacyContainer",
  components: { PrivacyTextDe, PrivacyTextEn, BackArrow, CookieModal },
  computed: {
    localeText() {
      if (this.$store.state.locale === "de") {
        return "PrivacyTextDe";
      } else {
        return "PrivacyTextEn";
      }
    },
  },
};
</script>
