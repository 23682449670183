<template>
  <div>
    <h1>Data protection information according to art. 13 DSGVO</h1>
    <p>As of: December 8, 2023</p>
    <p>
      According to art. 13 DSGVO we would like to inform you about the
      processing of your data.
    </p>
    <h2>1. Contact Details</h2>
    <p>You can reach us as follows:</p>
    <p>
      Meine Krone GmbH<br />
      Druffels Weg 1 <br />48653 Coesfeld<br />
      E-Mail: <a href="mailto:kontakt@sgservice.de">kontakt@sgservice.de</a
      ><br />
      Phone: +49 2541/94 06 0<br />
      Fax: +49 2541/94 06 20
    </p>
    <h2>2. Purpose and legal basis</h2>
    <p>
      If you provide us with the personal data mentioned below, we will only
      process this data in order to contact you, if necessary, to answer your
      questions and to process service requests (legal basis is Art. 6 para. 1
      lit. b DSGVO), for competitions (the legal basis here is Art. 6 para. 1
      lit. a or b DSGVO), in relation to advertising measures towards you (the
      legal basis here is Art. 6 para. 1 lit. a DSGVO, provided you have given
      your explicit consent, for example for a newsletter, an SMS or a call, or
      Art. 6 para. 1 lit. f DSGVO, the processing of personal data for the
      purpose of direct marketing can be considered as processing in the
      interest of a legitimate interest), in order to provide you with access to
      certain information or offers (the legal basis here is Art. 6 para. 1 lit.
      a (in the context of the above-mentioned advertising consent) or b (if
      this is necessary for the performance of a contract to which you are a
      party or for the implementation of pre-contractual measures which are
      carried out in response to your inquiry) DSGVO) as well as within the
      framework of legal requirements, for example for the fulfilment of legal
      obligations (the legal basis here is Art. 6 para. 1 lit. c and f DSGVO)
      and other legitimate interests in accordance with Art. 6 para. 1 lit. f
      DSGVO (for example to enforce our claims or to prosecute criminal
      offences).
    </p>
    <p>
      Of course, we will respect if you do not want to give us your personal
      data to support our relationship with you (especially for direct marketing
      or market research purposes).
    </p>
    <p>
      We only disclose personal data about you if we are legally obliged to do
      so (legal basis here is Art. 6 para. 1 lit. c DSGVO) or if we are obliged
      to do so by a court decision or if the disclosure is necessary to enforce
      or protect our interests, general terms and conditions or other agreements
      or the interests of third parties (legal basis here is Art. 6 para. 1 lit.
      f DSGVO). This applies accordingly with regard to the other processing of
      the data.
    </p>
    <p>The disclosure of the data is not for commercial purposes.</p>
    <p>
      Our employees and the service companies commissioned by us are obliged by
      us to maintain secrecy and to comply with the provisions of the DSGVO, the
      Federal Data Protection Act and other legal regulations. Access to
      personal data by our employees is limited to those employees who require
      the respective data due to their professional duties.
    </p>
    <p>
      Specifically, we would like to inform you about the following purposes,
      the data collected there and their use:
    </p>
    <h3>a. Use of personal data for registration / gaming experience</h3>
    <p>
      We collect personal data for the registration and your gaming experience
      (legal basis here is Art. 6 para. 1 lit. b DSGVO).
    </p>
    <p>
      These data are required for your registration with us and the gaming
      experience and are used exclusively for the establishment, execution or
      termination of the contract between you and us. Without this data it is
      not possible for us to let you participate in the gaming experience.
    </p>
    <p>The following data is collected:</p>
    <ul>
      <li>Player name</li>
      <li>E-mail address</li>
      <li>Password</li>
      <li>Whether you are 18 years or older</li>
    </ul>
    <h3>b. Contact form</h3>
    <p>
      If you send us inquiries via the contact form, your details from the
      inquiry form, including the contact details provided by you there, will be
      stored by us for the purpose of processing the inquiry and in the event of
      follow-up questions (the legal basis here is Art. 6 para. 1 lit. a or b
      DSGVO).
    </p>
    <h3>c. Newsletter</h3>
    <p>
      With the registration to the newsletter your e-mail address will be used
      with your consent for our own advertising purposes and to keep you
      informed. You can find more information about the newsletter in the
      "Newsletter" section.
    </p>
    <h3>d. Sweepstakes</h3>
    <p>
      For competitions we may collect information so that we can contact you if
      you win. These are for example title, first name, last name, your e-mail
      address. You are welcome to provide us with additional information, which
      helps us to plan our competitions better in the future. In the context of
      participating in competitions, you may also have the opportunity to
      register for our newsletter. In this case, please note the above-mentioned
      information.
    </p>
    <h2>3. Providing your data</h2>
    <p>
      The provision of your data by you is voluntary. Nevertheless, without this
      provision we cannot contact you, send you information and finally
      establish a business relationship with you.
    </p>
    <h2>4. Transfer of data to third parties or to a third country</h2>
    <p>
      We do not pass on your data to third parties without a legal basis
      (according to art. 6 para. 1 lit. c or f DSGVO). We also do not transfer
      your data to a third country, unless you yourself are in a third country
      or the execution of contracts requires the transfer of data to a third
      country.
    </p>
    <h2>5. Recipients of data</h2>
    <h3>a. Internal recipients:</h3>
    <p>
      Departments or persons who need this data for the execution of their tasks
      and for the purposes listed above.
    </p>
    <h3>b. External recipients:</h3>
    <p>
      We will transfer your data within the SCHMIDT.Gruppe, provided a
      legitimate interest according to Art. 6 para. 1 lit. f DSGVO exists and no
      basic rights, liberties or interests of yourself or the affected person
      which require the protection of personal information outweigh this
      interest. Legitimate interests especially include the aforementioned
      intereses and purposes.
    </p>
    <p>
      In addition to the recipients already listed here, we might transfer data
      in particular to the following external recipients (processors and third
      parties), but only if a legal basis according to Art. 6 par. 1 lit. a, b,
      c or f DSGVO (see above) or legally binding reasons exist:
    </p>
    <ul>
      <li>
        Service providers that are required for the fulfilment of our services
        (especially IT- and consulting services)
      </li>
      <li>
        Companies / institutions that support the enforcement of our claims
        (e.g. debt collection)
      </li>
      <li>
        Public entities and institutions (Public health departments, courts of
        law)
      </li>
      <li>Investigative authorities (Police, public prosecution department)</li>
      <li>Auxiliary persons</li>
    </ul>
    <h2>6. Your rights</h2>
    <p>
      You have the right to demand information from us at any time about the
      data stored about you, as well as its origin, recipients or categories of
      recipients to whom the data is passed on and the purpose of storage.
    </p>
    <p>
      If your data is not correct, you can of course also request that your data
      be corrected.
    </p>
    <p>
      You can also request the deletion of your data. We will comply with this
      request for deletion immediately. In doing so, we must observe any
      applicable legal storage regulations.
    </p>
    <p>
      If you have given your consent to the use of data, you can revoke it at
      any time with effect for the future.
    </p>
    <p>
      You also have a right to data transferability. We will provide you with
      your data in a machine-readable format upon request from your side.
    </p>
    <p class="bold-outline">
      Right of objection: You have the right to object at any time, for reasons
      arising from your particular situation, to the processing of personal data
      concerning you, which is carried out on the basis of art. 6, paragraph 1,
      letter e or f of the DPA; this also applies to profiling based on these
      provisions. If the personal data concerning you are processed for the
      purpose of direct marketing, you have the right to object at any time to
      the processing of personal data concerning you for the purpose of such
      marketing, including profiling, insofar as it is linked to such direct
      marketing.
    </p>
    <p>
      Please address all requests for information, requests for information,
      requests for deletion etc. or objections to the processing of data to the
      contact details of our data protection officer given below.
    </p>
    <p>
      If you are not satisfied with our data processing, you also have the right
      to complain to a competent supervisory authority for data protection.
    </p>
    <p>The responsible supervisory authority for data protection for us is:</p>
    <p>
      State Commissioner for Data Protection and Freedom of Information North
      Rhine-Westphalia<br />
      Kavalleriestr. 2-4<br />
      40213 Düsseldorf
    </p>
    <p>
      We would be pleased if you would talk to us first so that we can clarify
      possible ambiguities or uncertainties together.
    </p>
    <h2>7. Change of our data protection regulations</h2>
    <p>
      We reserve the right to change our security and data protection measures
      if this becomes necessary due to technical developments, changes in our
      data processing or legal changes. In these cases we will also adapt our
      data protection information accordingly. Therefore, please refer to the
      current version of our data protection declaration.
    </p>
    <h2>8. Deletion</h2>
    <p>
      The deletion of stored personal data is carried out, in addition to the
      retention periods specifically mentioned in this information, if you
      revoke your consent to storage, if the knowledge of the data is no longer
      required to fulfill the purpose for which it was stored, or if storage of
      the data is not permitted for other legal reasons, unless legal storage
      regulations speak against deletion, in which case the data is blocked
      instead of being deleted.
    </p>
    <h2>9. Automated decision making, including profiling</h2>
    <p>
      As a responsible company, we do not use automated decision making
      including profiling.
    </p>
    <h2>10. Contact person for questions regarding data protection</h2>
    <p>
      Should you have any further questions regarding the collection, processing
      and use of your personal data, please contact our data protection officer.
    </p>
    <p>
      Meine Krone GmbH<br />
      Data protection officer<br />
      Druffels Weg 1<br />
      48653 Coesfeld<br />
      E-Mail: <a href="mailto:kontakt@sgservice.de">kontakt@sgservice.de</a
      ><br />
      Phone: +49 2541/94 06 0<br />
      Fax: +49 2541/94 06 20<br />
      E-Mail:
      <a href="mailto:datenschutz@schmidtgruppe.de">
        datenschutz@schmidtgruppe.de</a
      >
    </p>
    <h2>11. Newsletter</h2>
    <p>We offer the possibility to receive news and offers via newsletter.</p>
    <p>
      With the registration to the newsletter your e-mail address will be used
      with your consent for our own advertising purposes.
    </p>
    <p>
      For the sending of newsletters we use specialized service providers to
      whom we transfer the necessary personal data. These service providers
      process your personal data only on our behalf and on our instructions. We
      guarantee compliance with data protection regulations and the necessary
      security measures.
    </p>
    <p>
      The above-mentioned consent to receive a newsletter by e-mail can be
      revoked at any time. In every newsletter you will find a link with which
      you can revoke your consent.
    </p>
    <p>
      As a precautionary measure, we would like to point out that receiving
      and/or cancelling the newsletter usually does not incur any costs other
      than the transmission costs according to the basic tariff. However, there
      may be constellations in which the flat rates in use today no longer apply
      (e.g. in non-European countries or similar).
    </p>
    <h2>12. Kleine Krone App</h2>
    <p>
      We also use the Google Firebase service to analyze usage behavior in the
      app.
    </p>
    <p>
      Firebase is a real-time database with which real-time information can be
      embedded in your own app. The user data is transferred anonymously to
      Firebase. Firebase is a Google subsidiary and is based in San Francisco
      (CA), USA. The privacy policy of Firebase can be found at
      <a
        href="https://www.firebase.com/terms/privacy-policy.html"
        target="_blank"
        >https://www.firebase.com/terms/privacy-policy.html</a
      >.
    </p>
    <h2>13. Payment processing</h2>
    <h3>Adyen</h3>
    <p>
      When you make payments to Meine Krone using a credit or debit card, you
      will be required to provide personal information, such as your credit card
      number, to our third party service provider.
    </p>
    <p>
      If you pay by and with a credit or debit card, the transaction will be
      processed by our payment gateway provider Adyen B.V., Simon
      Carmiggeltstraat 6-50, 5th floor, 1011 DJ Amsterdam, The Netherlands
      ("Adyen").
    </p>
    <p>
      You will provide your credit or debit card information directly to Adyen.
      Information you provide to Adyen is not under our control and is subject
      to Adyen's Privacy Policy, which is available here:
      <a
        href="https://www.adyen.com/policies-and-disclaimer/privacy-policy
"
        target="_blank"
      >
        https://www.adyen.com/policies-and-disclaimer/privacy-policy</a
      >.
    </p>
    <p>The legal basis for this is Art. 6 para. 1 lit. b DSGVO.</p>
    <p>We do not store credit card numbers.</p>
    <h3>PayPal</h3>
    <p>
      We offer the option of making payments via the payment service provider
      PayPal (PayPal (Europe) S.à.r.l. et Cie, S.C.A., 22-24 Boulevard Royal,
      L-2449 Luxembourg). In particular, the following data may be transmitted
      to PayPal:
    </p>
    <ul>
      <li>First name</li>
      <li>Last name</li>
      <li>Your address</li>
      <li>e-mail address</li>
      <li>telephone number</li>
    </ul>
    <p>
      In the case of your use of PayPal, this transmission corresponds to our
      legitimate interest in offering an efficient and secure payment method
      (legal basis is then Art. 6 para. 1 lit. f GDPR). The data transfer is
      then necessary for the fulfillment of the contract (legal basis is then
      Art. 6 para. 1 lit. b. GDPR).
    </p>
    <p>
      We receive information from PayPal as to whether the payment has been
      successfully completed or rejected or whether a review is pending.
    </p>

    <p>
      The processing of the data is not required by law and is only
      contractually required if you select this payment method. Unfortunately,
      we cannot process a payment via PayPal without the transmission of your
      personal data.
    </p>
    <p>
      Details on payment with PayPal (in particular on any credit checks carried
      out by PayPal or data forwarding by PayPal) can be found in PayPal's terms
      and conditions and privacy policy at:
      <a
        href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full"
        target="_blank"
        >https://www.paypal.com/de/webapps/mpp/ua/privacy-full</a
      >
    </p>

    <h2>14. Information on further functions of our website</h2>
    <h3>Cookies</h3>
    <h4>a) General</h4>
    <p>
      To make visiting our website attractive and to enable the use of certain
      functions, we use so-called cookies on various pages. These are small text
      files that are stored on your end device. Some of the cookies we use are
      deleted after the end of the browser session, i.e. after closing your
      browser (so-called session cookies). Other cookies remain on your device
      and enable us or our partner companies to recognize your browser the next
      time you visit us (persistent cookies). We use cookies to personalize
      content and advertisements and to analyze access to our website. We also
      share information about your use of our website with our social media,
      advertising and analysis partners.
    </p>
    <h4>b) Legal basis</h4>
    <p>
      The legal basis for the use of cookies is your consent. We only use one or
      more cookies if you have agreed to the use of cookies in the cookie popup
      that appears when you visit our website. The legal basis is then Art. 6
      paragraph 6 1 lit. a DSGVO. You can revoke this consent at any time with
      effect for the future by using the following link:
      <a href="#" @click.prevent="$emit('showBanner')">Cookie-Settings</a>.
    </p>
    <h3>Privacy policy for the use of Google Analytics</h3>
    <p>
      This website uses functions of the web analysis service Google Analytics.
      The provider is Google Inc. 1600 Amphitheatre Parkway Mountain View, CA
      94043, USA. Google Analytics uses so-called "cookies". These are text
      files which are stored on your computer and which enable an analysis of
      your use of the website. The information generated by the cookie about
      your use of the website such as:
    </p>
    <ul>
      <li>Browser type/version,</li>
      <li>Operating system used,</li>
      <li>Referrer URL (the previously visited page),</li>
      <li>Host name of the accessing computer (IP address),</li>
      <li>Time of the server request</li>
    </ul>
    <p>
      are usually transferred to a Google server in the USA and stored there.
    </p>
    <h3>IP anonymization:</h3>
    <p>
      However, when activating IP anonymization on this website, your IP address
      will be shortened by Google within member states of the European Union or
      in other states which are party to the Agreement on the European Economic
      Area. Only in exceptional cases will the full IP address be transferred to
      a Google server in the USA and shortened there. On behalf of the operator
      of this website, Google will use this information for the purpose of
      evaluating your use of the website, compiling reports on website activity
      and providing other services relating to website activity and internet
      usage. The IP address transmitted by your browser in the context of Google
      Analytics is not combined with other data from Google.
    </p>
    <h3>Refusal:</h3>
    <p>
      You may refuse the use of cookies by selecting the appropriate settings on
      your browser, however please note that if you do this you may not be able
      to use the full functionality of this website. You can also prevent the
      collection of data generated by the cookie and related to your use of the
      website (including your IP address) to Google and the processing of this
      data by Google by downloading and installing the browser plugin available
      at the following link:
      <a href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank"
        >http://tools.google.com/dlpage/gaoptout?hl=de</a
      >.
    </p>
    <p>
      As an alternative to the browser add-on, especially for browsers on mobile
      devices, you can also prevent Google Analytics from collecting data by
      clicking on this link:
      <a href="#" @click.prevent="$emit('showBanner')"
        >Disable Google Analytics</a
      >. An opt-out cookie will be set to prevent the future collection of your
      data when you visit this website. The opt-out cookie is only valid in this
      browser and only for our website and is stored on your device. If you
      delete the cookies in this browser, you will have to set the opt-out
      cookie again.
    </p>
    <h3>Further information:</h3>
    <p>
      We also use Google Analytics to evaluate data from double-click cookies
      and also Google Ads for statistical purposes. If you don't want this, you
      can deactivate this in the
      <a href="http://www.google.com/settings/ads/onweb/?hl=de" target="_blank"
        >Ads Preferences Manager</a
      >.
    </p>
    <h3>Facebook-Connect</h3>
    <p>
      We offer you the possibility to register with us via Facebook-Connect. The
      legal basis for this is your consent according to Art. 6 para. 1 lit. a
      DSGVO:
    </p>
    <p>
      "By clicking on "register with Facebook" I agree that data will be
      automatically transmitted to My Crown through Facebook-Connect and
      subsequently processed. This concerns my profile picture, my name and my
      date of birth. I have read the privacy policy. I can revoke this consent
      at any time by sending an e-mail to
      <a href="mailto:datenschutz@schmidtgruppe.de"
        >datenschutz@schmidtgruppe.de</a
      >.“
    </p>
    <p>
      An additional registration is therefore not necessary. For registration
      you will be redirected to the Facebook page where you can log in with your
      Facebook login data. This will link your Facebook profile and Meine Krone.
      Through this link we automatically receive the following information from
      Facebook:
    </p>

    <ul>
      <li>Name</li>
      <li>Date of birth</li>
      <li>Profile picture</li>
    </ul>
    <p>
      We use this information only to enable you to participate in the gaming
      experience and for the other purposes set out in this Privacy Policy.
    </p>
    <p>
      For more information about Facebook Connect and privacy settings, please
      see the Facebook Privacy Notice and Terms of Use.
    </p>

    <h3>Google Ads</h3>
    <p>
      We have integrated Google Ads on our website. Google Ads is a service
      provided by Google Ireland Limited ("Google"), Gordon House, Barrow
      Street, Dublin 4, Ireland, to display targeted advertising to users.
      Google Ads uses cookies and other browser technologies to evaluate user
      behaviour and recognize users.
    </p>
    <p>
      Google Ads collects information about visitor behavior on various
      websites. This information is used to optimize the relevance of
      advertising. Furthermore, Google Ads delivers targeted advertising based
      on behavioral profiles and geographical location. Your IP address and
      other identification features such as your user agent are transmitted to
      the provider.
    </p>
    <p>
      If you are registered with a Google service, Google Ads can assign the
      visit to your account. Even if you are not registered with Google or have
      not logged in, it is possible that the provider will find out and save
      your IP address and other identification features.
    </p>
    <p>
      In this case, your data will be passed on to the operator of Google Ads,
      Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland.
    </p>
    <p>
      The use of Google Ads is based on your consent in accordance with Art. 6
      para. 1 lit. a. GDPR and § 25 para. 1 TTDSG.
    </p>
    <p>
      Any third country transfer to the USA takes place on the basis of the
      European Commission's adequacy decision on the EU-US Data Privacy
      Framework of July 10, 2023.
    </p>
    <p>
      The specific storage period of the processed data cannot be influenced by
      us, but is determined by Google Ireland Limited. Further information can
      be found in the privacy policy for Google Ads:
      <a href="https://policies.google.com/privacy" target="_blank"
        >https://policies.google.com/privacy</a
      >.
    </p>

    <h3>Google AdSense</h3>
    <p>
      Our website uses Google AdSense. The provider is Google Inc, 1600
      Amphitheatre Parkway, Mountain View, CA 94043, USA.
    </p>
    <p>
      Google AdSense is used to integrate advertisements and sets cookies.
      Cookies are small text files that your web browser stores on your end
      device in order to analyze the use of the website. Google AdSense also
      uses web beacons. Web beacons are invisible graphics that allow us to
      analyze visitor traffic on our website.
    </p>
    <p>
      Information generated by cookies and web beacons is transferred to Google
      servers and stored there. The server is located in the USA. Google may
      pass this information on to contractual partners. However, Google will not
      merge your IP address with other data stored by you.
    </p>
    <p>
      AdSense cookies are stored on the basis of Art. 6 para. 1 lit. a GDPR.
    </p>
    <p>
      With a modern web browser, you can monitor, restrict and prevent the
      setting of cookies. Deactivating cookies may result in limited
      functionality of our website. By giving your consent, you agree to the
      processing of the data collected about you by Google in the manner and for
      the purposes described above.
    </p>
    <p>
      Any third country transfer to the USA is based on the adequacy decision of
      the European Commission on the EU-US Data Privacy Framework of 10.07.2023.
    </p>

    <h3>Bing Ads</h3>
    <p>
      We have integrated Bing Ads on our website. Bing Ads is a service provided
      by Microsoft Corporation to display targeted advertising to users. Bing
      Ads uses cookies and other browser technologies to evaluate user behaviour
      and recognize users.
    </p>
    <p>
      Bing Ads collects information about visitor behavior on various websites.
      This information is used to optimize the relevance of advertising.
      Furthermore, Bing Ads delivers targeted advertising based on behavioral
      profiles and geographical location. Your IP address and other
      identification features such as your user agent are transmitted to the
      provider.
    </p>
    <p>
      In this case, your data will be passed on to the operator of Bing Ads,
      Microsoft Corporation, One Microsoft Way Redmond, WA 98052-6399, United
      States.
    </p>
    <p>
      The use of Bing Ads is based on your consent in accordance with Art. 6
      para. 1 lit. a. GDPR and § 25 para. 1 TTDSG.
    </p>
    <p>
      Any third country transfer to the USA takes place on the basis of the
      European Commission's adequacy decision on the EU-US Data Privacy
      Framework of July 10, 2023.
    </p>
    <p>
      The specific storage period of the processed data cannot be influenced by
      us, but is determined by Microsoft Corporation. Further information can be
      found in the privacy policy for Bing Ads:
      <a
        href="https://privacy.microsoft.com/de-de/privacystatement"
        target="_blank"
        >https://privacy.microsoft.com/de-de/privacystatement</a
      >.
    </p>

    <h3>Facebook Pixel</h3>
    <p>
      We use Facebook Pixel from Meta Platforms Ireland Limited, 4 Grand Canal
      Square, Grand Canal Harbour, Dublin 2, Ireland, to create so-called Custom
      Audiences, i.e. to segment groups of visitors to our online offering,
      determine conversion rates and subsequently optimize them. This happens in
      particular when you interact with advertisements that we have placed with
      Meta Platforms Ireland Limited.
    </p>
    <p>
      The use of Facebook Pixel is based on your consent in accordance with Art.
      6 para. 1 lit. a. GDPR and § 25 para. 1 TTDSG.
    </p>
    <p>
      Any third country transfer to the USA is based on the adequacy decision of
      the European Commission on the EU-US Data Privacy Framework of 10.07.2023.
    </p>
    <p>
      The specific storage period of the processed data cannot be influenced by
      us, but is determined by Meta Platforms Ireland Limited. Further
      information can be found in the privacy policy for Facebook Pixel:
      <a href="https://www.facebook.com/privacy/explanation" target="_blank"
        >https://www.facebook.com/privacy/explanation</a
      >.
    </p>
  </div>
</template>

<script>
export default {
  name: "PrivacyTextEn",
};
</script>
