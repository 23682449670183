<template>
  <b-col id="tac-container" cols="12" class="content-row px-3 mb-3">
    <BackArrow @back="$emit('back')" /><component :is="localeText"
  /></b-col>
</template>

<script>
import TacTextDe from "@/components/legal/tac-text_de.vue";
import TacTextEn from "@/components/legal/tac-text_en.vue";
import BackArrow from "@/components/legal/back-arrow.vue";

export default {
  name: "TacContainer",
  components: { TacTextDe, TacTextEn, BackArrow },
  computed: {
    localeText() {
      if (this.$store.state.locale === "de") {
        return "TacTextDe";
      } else {
        return "TacTextEn";
      }
    },
  },
};
</script>
