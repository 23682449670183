<template>
  <div>
    <h1>Imprint</h1>

    <div>
      <span class="bold">Meine Krone GmbH</span><br />
      Druffels Weg 1<br />
      48653 Coesfeld, D<br />
    </div>
    <div class="mt-3">
      <span class="text-uppercase bold">Managing director:</span><br />
      Arne Schmidt<br />
    </div>
    <div class="mt-3">
      <span class="text-uppercase bold">Contact Details</span><br />
      Phone: +49 2541 94 06 0<br />
      Fax: +49 2541 94 06 20 <br />
      E-Mail:
      <a href="mailto:support@kronesupport.zendesk.com"
        >support@kronesupport.zendesk.com</a
      ><br />
      Website: <a href="www.kleinekrone.de">www.kleinekrone.de</a> <br />
    </div>
    <div class="mt-3">
      <span class="text-uppercase bold"> Commercial register</span><br />
      Amtsgericht Coesfeld: HRB 17958<br />
    </div>
    <div class="mt-3">
      <span class="text-uppercase bold"> VAT ID</span><br />
      DE322521921
    </div>
  </div>
</template>

<script>
export default {
  name: "ImprintTextEn",
};
</script>
