<template>
  <div>
    <h1>Datenschutzinformationen gem. Art. 13 DSGVO</h1>
    <p>Stand: 08.12.2023</p>
    <p>
      Gem. Art. 13 DSGVO möchten wir Dich über die Verarbeitung deiner Daten
      informieren.
    </p>
    <h2>1. Kontaktdaten</h2>
    <p>Du erreichst uns wie folgt:</p>
    <p>
      Meine Krone GmbH<br />
      Druffels Weg 1 <br />48653 Coesfeld<br />
      E-Mail: <a href="mailto:kontakt@sgservice.de">kontakt@sgservice.de</a
      ><br />
      Telefon: +49 2541/94 06 0<br />
      Telefax: +49 2541/94 06 20
    </p>
    <h2>2. Zweckbestimmung und Rechtsgrundlagen</h2>
    <p>
      Soweit Du uns die weiter unten genannten personenbezogenen Daten zur
      Verfügung stellst, verarbeiten wir diese nur, um ggf. Kontakt mit dir
      aufzunehmen, zur Beantwortung deiner Anfragen und zur Abwicklung von
      Serviceanfragen (Rechtsgrundlage ist hier jeweils Art. 6 Abs. 1 lit. b
      DSGVO), für Gewinnspiele (Rechtsgrundlage ist hier Art. 6 Abs. 1 lit. a
      oder b DSGVO), in Bezug auf Werbemaßnahmen Dir gegenüber (Rechtsgrundlage
      ist hier Art. 6 Abs. 1 lit. a DSGVO, sofern Du eine explizite
      Einwilligung, beispielsweise für einen Newsletter, eine SMS oder einen
      Anruf, gegeben hast oder Art. 6 Abs. 1 lit. f DSGVO, die Verarbeitung
      personenbezogener Daten zum Zwecke der Direktwerbung kann als eine einem
      berechtigten Interesse dienende Verarbeitung betrachtet werden), um Dir
      Zugang zu bestimmten Informationen oder Angeboten zu verschaffen
      (Rechtsgrundlage ist hier Art. 6 Abs. 1 lit. a (im Rahmen der oben
      genannten Werbeeinwilligung) oder b (falls dies zu Erfüllung eines
      Vertrags, dessen Vertragspartei Du bist, oder zur Durchführung
      vorvertraglicher Maßnahmen erforderlich, die deiner Anfrage erfolgen,
      erforderlich ist) DSGVO) sowie im Rahmen gesetzlicher Vorgaben,
      beispielsweise zur Erfüllung von rechtlichen Verpflichtungen
      (Rechtsgrundlage ist hier Art. 6 Abs. 1 lit. c und f DSGVO) und weiteren
      berechtigten Interessen gem. Art. 6 Abs.1 lit. f DSGVO (beispielsweise zur
      Durchsetzung unserer Forderungen oder zur Verfolgung von Straftaten).
    </p>
    <p>
      Selbstverständlich respektieren wir es, wenn uns deine personenbezogenen
      Daten nicht zur Unterstützung unserer Beziehung zu dir (insbesondere für
      Direktmarketing oder zu Marktforschungszwecken) überlassen willst.
    </p>
    <p>
      Wir geben persönliche Daten über Dich nur bekannt, wenn wir hierzu
      gesetzlich verpflichtet sind (Rechtsgrundlage ist hier Art. 6 Abs. 1 lit.
      c DSGVO) bzw. sofern wir durch eine gerichtliche Entscheidung dazu
      verpflichtet sind oder wenn die Weitergabe erforderlich ist, um unsere
      Interessen, Allgemeinen Geschäftsbedingungen oder andere Vereinbarungen
      oder die Interessen Dritter durchzusetzen oder zu schützen
      (Rechtsgrundlage ist hier Art. 6 Abs. 1 lit. f DSGVO). Dies gilt
      entsprechend in Bezug auf die sonstige Verarbeitung der Daten.
    </p>
    <p>Die Bekanntgabe der Daten erfolgt nicht zu wirtschaftlichen Zwecken.</p>
    <p>
      Unsere Mitarbeiter und die von uns beauftragten Dienstleistungsunternehmen
      sind von uns zur Verschwiegenheit und zur Einhaltung der Bestimmungen der
      DSGVO, des Bundesdatenschutzgesetzes und anderer gesetzlicher Regelungen
      verpflichtet. Der Zugriff auf personenbezogene Daten durch unsere
      Mitarbeiter ist auf die Mitarbeiter beschränkt, die die jeweiligen Daten
      aufgrund ihrer beruflichen Aufgaben benötigen.
    </p>
    <p>
      Konkret möchten wir Dich über die folgenden Zwecke, die dort erhobenen
      Daten und deren Verwendung informieren:
    </p>
    <h3>
      a. Verwendung personenbezogener Daten für die Registrierung / das
      Spielerlebnis
    </h3>
    <p>
      Wir erheben für die Registrierung und Dein Spielerlebnis personenbezogene
      Daten (Rechtsgrundlage ist hier Art. 6 Abs. 1 lit. b DSGVO).
    </p>
    <p>
      Diese Daten sind für deine Registrierung bei uns und das Spielerlebnis
      erforderlich und werden ausschließlich für die Begründung, Durchführung
      oder Beendigung des Vertrages zwischen Dir und uns genutzt. Ohne diese
      Daten ist es uns nicht möglich, Dich am Spielerlebnis teilnehmen zu
      lassen.
    </p>
    <p>Folgende Daten werden erhoben:</p>
    <ul>
      <li>Spielername</li>
      <li>E-Mail-Adresse</li>
      <li>Passwort</li>
      <li>Ob Du 18 Jahre oder älter bist</li>
    </ul>
    <h3>b. Kontaktformular</h3>
    <p>
      Wenn Du uns per Kontaktformular Anfragen zukommen lässt, werden deine
      Angaben aus dem Anfrageformular inklusive der von Dir dort angegebenen
      Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
      Anschlussfragen bei uns gespeichert (Rechtsgrundlage ist hier Art. 6 Abs.
      1 lit. a oder b DSGVO).
    </p>
    <h3>c. Newsletter</h3>
    <p>
      Mit der Anmeldung zum Newsletter wird deine E-Mail-Adresse mit deiner
      Einwilligung für eigene Werbezwecke und um Dich zu informieren genutzt.
      Weitere Informationen zum Thema Newsletter findest Du im Punkt
      „Newsletter“.
    </p>
    <h3>d. Gewinnspiele</h3>
    <p>
      Für Gewinnspiele erheben wir ggf. Angaben, damit wir dich, falls du
      gewinnst, kontaktieren können. Dies sind beispielsweise Anrede, Vorname,
      Nachname, deine E-Mail-Adresse. Gerne kannst Du uns weitere Daten
      mitteilen, das hilft uns dabei, unsere Gewinnspiele künftig besser zu
      planen. Im Rahmen der Teilnahme an Gewinnspielen hast Du ggf. auch die
      Möglichkeit, Dich für unseren Newsletter anzumelden. Bitte beachte dann
      hier die oben genannten Hinweise.
    </p>
    <h2>3. Bereitstellung deiner Daten</h2>
    <p>
      Die Bereitstellung deiner Daten durch Dich erfolgt freiwillig. Gleichwohl
      können wir ohne diese Bereitstellung nicht mit Dir Kontakt aufnehmen, dir
      Informationen zukommen lassen und abschließend eine Geschäftsbeziehung mit
      Dir aufnehmen.
    </p>
    <h2>4. Weitergabe von Daten an Dritte oder in ein Drittland</h2>
    <p>
      Wir geben Deine Daten ohne eine gesetzliche Grundlage (gem. Art 6 Abs. 1
      lit. c oder f DSGVO) nicht an Dritte weiter. Auch geben wir Deine Daten
      nicht in ein Drittland weiter, außer Du selbst befindest Dich in einem
      Drittland oder die Abwicklung von Verträgen erfordert die Weitergabe der
      Daten in ein Drittland.
    </p>
    <h2>5. Empfänger von Daten</h2>
    <h3>a. Interne Empfänger:</h3>
    <p>
      Fachabteilungen und Personen, die die Kenntnis dieser Daten zur Erfüllung
      ihrer Aufgaben und der oben genannten Zwecke benötigen.
    </p>
    <h3>b. Externe Empfänger:</h3>
    <p>
      Wir geben Deine Daten innerhalb der SCHMIDT.Gruppe weiter, sofern ein
      berechtigtes Interesse gem. Art. 6 Abs. 1 lit. f DSGVO vorliegt und sofern
      nicht Deine Interessen oder Grundrechte und Grundfreiheiten der
      betroffenen Person, die den Schutz personenbezogener Daten erfordern,
      überwiegen. Berechtigte Interessen in diesen Fällen sind insbesondere die
      oben genannten Interessen zu den dort angegebenen Zwecken.
    </p>
    <p>
      Über die in dieser Information bereits dargestellten Empfängern, geben wir
      ggf. Daten an insbesondere folgende externe Empfänger (Auftragsverarbeiter
      und Dritte) weiter, jedoch nur, wenn hierfür eine gesetzliche Grundlage
      (gem. Art 6 Abs. 1 lit. a, b, c oder f DSGVO, siehe oben) vorhanden ist
      oder gesetzlich zwingende Gründe bestehen:
    </p>
    <ul>
      <li>
        Dienstleistungsunternehmen, die zur Erbringung unserer Dienstleistungen
        erforderlich sind (insbesondere IT-Dienstleistungsunternehmen,
        Beratungsunternehmen)
      </li>
      <li>
        Unternehmen / Institutionen zur Unterstützung bei der Durchsetzung
        unserer Ansprüche (z.B. Inkassounternehmen)
      </li>
      <li>
        Öffentliche Stellen und Institutionen (Gesundheitsämter, Gerichte)
      </li>
      <li>Ermittlungsbehörden (Polizei, Staatsanwaltschaft)</li>
      <li>Erfüllungsgehilfen</li>
    </ul>

    <h2>6. Deine Rechte</h2>
    <p>
      Du hast das Recht, von uns jederzeit Auskunft zu verlangen über die zu dir
      bei uns gespeicherten Daten, sowie zu deren Herkunft, Empfängern oder
      Kategorien von Empfängern, an die diese Daten weitergegeben werden und den
      Zweck der Speicherung.
    </p>
    <p>
      Sofern deine Daten bei uns nicht richtig sein sollten, kannst Du natürlich
      auch eine Berichtigung deiner Daten verlangen.
    </p>
    <p>
      Auch kannst Du eine Löschung deiner Daten verlangen. Diesem Wunsch auf
      Löschung werden wir unverzüglich nachkommen. Wir müssen dabei eventuell
      geltende gesetzliche Aufbewahrungsvorschriften beachten.
    </p>
    <p>
      Wenn Du eine Einwilligung zur Nutzung von Daten erteilt hast, kannst du
      diese jederzeit mit Wirkung für die Zukunft widerrufen.
    </p>
    <p>
      Du hast auch ein Recht auf Datenübertragbarkeit. Wir werden dir, bei einem
      entsprechenden Antrag von deiner Seite, deine Daten in einem
      maschinenlesbaren Format zur Verfügung stellen.
    </p>
    <p class="bold-outline">
      Widerspruchsrecht: Du hast das Recht, aus Gründen, die sich aus deiner
      besonderen Situation ergeben, jederzeit gegen die Verarbeitung der dich
      betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit.
      e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf
      diese Bestimmungen gestütztes Profiling. Werden die Dich betreffenden
      personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, hast
      Du das Recht, jederzeit Widerspruch gegen die Verarbeitung der dich
      betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung
      einzulegen; dies gilt auch für das Profiling, soweit es mit solcher
      Direktwerbung in Verbindung steht.
    </p>
    <p>
      Alle Informationswünsche, Auskunftsanfragen, Anträge auf Löschung etc.
      oder Widersprüche zur Datenverarbeitung richte bitte an die unten
      angegebenen Kontaktdaten unseres Datenschutzbeauftragten.
    </p>
    <p>
      Du hast, falls Du mit unserer Datenverarbeitung nicht zufrieden sein
      solltest, auch das Recht, Dich bei einer zuständigen Aufsichtsbehörde für
      den Datenschutz zu beschweren.
    </p>
    <p>Die zuständige Aufsichtsbehörde für den Datenschutz für uns ist:</p>
    <p>
      Landesbeauftragte für Datenschutz und Informationsfreiheit<br />

      Nordrhein-Westfalen Kavalleriestr. 2-4 <br />40213 Düsseldorf
    </p>
    <p>
      Wir freuen uns, wenn Du zuerst mit uns sprichst, damit wir mögliche
      Unklarheiten oder Unsicherheiten gemeinsam klären können.
    </p>
    <h2>
      7. Änderung unserer Da<wbr />ten<wbr />schutz<wbr />be<wbr />stim<wbr />mun<wbr />gen
    </h2>
    <p>
      Wir behalten uns das Recht vor, unsere Sicherheits- und
      Datenschutzmaßnahmen zu verändern, soweit dies wegen der technischen
      Entwicklung, Änderungen in unserer Datenverarbeitung oder aufgrund von
      gesetzlichen Änderungen erforderlich wird. In diesen Fällen werden wir
      auch unsere Hinweise zum Datenschutz entsprechend anpassen. Bitte beachte
      daher die jeweils aktuelle Version unserer Datenschutzerklärung.
    </p>
    <h2>8. Löschung</h2>
    <p>
      Die Löschung gespeicherter personenbezogener Daten erfolgt, neben den in
      dieser Information konkret genannten Aufbewahrungsfristen, wenn Du deine
      Einwilligung zur Speicherung widerrufen, wenn ihre Kenntnis zur Erfüllung
      des mit der Speicherung verfolgten Zwecks nicht mehr erforderlich ist oder
      wenn ihre Speicherung aus sonstigen gesetzlichen Gründen unzulässig ist,
      es sei denn gesetzliche Aufbewahrungsvorschriften sprechen gegen eine
      Löschung, dann tritt an Stelle der Löschung eine Sperrung der Daten.
    </p>
    <h2>
      9. Automatisierte Ent<wbr />schei<wbr />dungs<wbr />fin<wbr />dung,
      einschließlich Profiling
    </h2>
    <p>
      Als verantwortungsvolles Unternehmen verzichten wir auf eine
      automatisierte Entscheidungsfindung einschließlich Profiling.
    </p>
    <h2>10. Ansprechpartner bei Fragen zum Datenschutz</h2>
    <p>
      Solltest Du weitere Fragen zur Erhebung, Verarbeitung und Nutzung deiner
      persönlichen Daten haben, wende Dich bitte an unseren
      Datenschutzbeauftragten.
    </p>
    <p>
      Meine Krone GmbH<br />
      Datenschutzbeauftragter<br />
      Druffels Weg 1<br />
      48653 Coesfeld<br />
      E-Mail: <a href="mailto:kontakt@sgservice.de">kontakt@sgservice.de</a
      ><br />
      Telefon: +49 2541/94 06 0<br />
      Telefax: +49 2541/94 06 20<br />
      E-Mail:
      <a href="mailto:datenschutz@schmidtgruppe.de">
        datenschutz@schmidtgruppe.de</a
      >
    </p>
    <h2>11. Newsletter</h2>
    <p>
      Wir bieten die Möglichkeit, sich per Newsletter über News und Angebote zu
      informieren.
    </p>
    <p>
      Mit der Anmeldung zum Newsletter wird deine E-Mail-Adresse mit deiner
      Einwilligung für eigene Werbezwecke genutzt.
    </p>
    <p>
      Für die Zusendung von Newslettern bedienen wir uns hierfür spezialisierter
      Dienstleister, an die wir die dafür erforderlichen personenbezogenen Daten
      übermitteln. Diese Dienstleister verarbeiten deine personenbezogenen Daten
      nur im Auftrag und auf Weisung von uns. Die Einhaltung der
      Datenschutzbestimmungen sowie die erforderlichen Sicherheitsmaßnahmen sind
      von uns gewährleistet.
    </p>
    <p>
      Die oben genannte Einwilligung zum Empfang eines Newsletters per E-Mail
      ist jederzeit widerrufbar. In jedem Newsletter findest Du dazu einen Link,
      mit dem Du deine Einwilligung widerrufen kannst.
    </p>
    <p>
      Wir weisen Dich vorsorglich daraufhin, dass für den Empfang und/oder die
      Abbestellung des Newsletters üblicherweise keine anderen als die
      Übermittlungskosten nach dem Basistarif entstehen. Es mag jedoch
      Konstellationen geben, in denen die heute üblichen Flatrates nicht mehr
      greifen (z. B. im außereuropäischen Ausland o. ä.).
    </p>
    <h2>12. Kleine Krone App</h2>
    <p>
      Zur Analyse des Nutzungsverhalten in der App nutzen wir außerdem den
      Service Google Firebase.
    </p>
    <p>
      Firebase ist eine Echtzeit-Datenbank, mit der sich Echtzeitinformationen
      in die eigene App einbetten lassen. Hierbei werden die Nutzerdaten
      anonymisiert an Firebase übermittelt. Firebase ist eine Google-Tochter und
      hat seinen Sitz in San Francisco (CA), USA. Die Datenschutzerklärung von
      Firebase findest Du unter
      <a
        target="_blank"
        href="https://www.firebase.com/terms/privacy-policy.html"
        >https://www.firebase.com/terms/privacy-policy.html</a
      >.
    </p>
    <h2>13. Zahlungsabwicklung</h2>
    <h3>Adyen</h3>
    <p>
      Wenn Du Zahlungen bei Meine Krone mit einer Kredit- oder Debitkarte
      vornimmst, musst Du unserem Drittanbieter personenbezogene Daten, wie
      deine Kreditkartennummer, zur Verfügung stellen.
    </p>
    <p>
      Wenn Du bei und per Kredit- oder Debitkarte bezahlst, erfolgt die
      Abwicklung über unseren Payment-Gateway-Anbieter Adyen B.V., Simon
      Carmiggeltstraat 6—50, 5. Stock, 1011 DJ Amsterdam, Niederlande („Adyen“).
    </p>
    <p>
      Du wirst deine Kredit- oder Debitkarteninformationen direkt an Adyen
      weitergeben. Informationen, die Du Adyen zur Verfügung stellst, stehen
      nicht unter unserer Kontrolle und unterliegen der Datenschutzerklärung von
      Adyen, die hier verfügbar ist:<a
        target="_blank"
        href="https://www.adyen.com/policies-and-disclaimer/privacy-policy
"
      >
        https://www.adyen.com/policies-and-disclaimer/privacy-policy</a
      >.
    </p>
    <p>Die Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. b DSGVO.</p>
    <p>Wir speichern keine Kreditkartennummern.</p>
    <h3>PayPal</h3>
    <p>
      Wir bieten die Möglichkeit, Zahlungen über den Zahlungsdienstleister
      PayPal (PayPal (Europe) S.à.r.l. et Cie, S.C.A., 22-24 Boulevard Royal,
      L-2449 Luxembourg) zu leisten. Hierbei können insbesondere die folgenden
      Daten an Paypal übermittelt werden:
    </p>
    <ul>
      <li>Vorname</li>
      <li>Nachname</li>
      <li>Adresse</li>
      <li>E-Mail-Adresse</li>
      <li>Telefonnummer</li>
    </ul>

    <p>
      Dies Übermittlung entspricht im Falle Ihrer Nutzung von Paypal unserem
      berechtigten Interesse, eine effiziente und sichere Zahlungsmethode
      anzubieten (Rechtsgrundlage ist dann Art. 6 Abs. 1 lit. f DSGVO). Die
      Datenweitergabe ist dann für die Vertragserfüllung erforderlich ist
      (Rechtsgrundlage ist dann Art. 6 Abs. 1 lit b. DSGVO).
    </p>
    <p>
      Wir erhalten von Paypal eine Auskunft, ob die Zahlung erfolgreich
      durchgeführt oder abgelehnt wurde oder eine Überprüfung aussteht.
    </p>
    <p>
      Die Verarbeitung der Daten ist hierbei weder gesetzlich vorgeschrieben und
      nur dann vertraglich erforderlich, wenn Du diese Zahlungsart auswählst.
      Ohne die Übermittlung Deiner personenbezogenen Daten können wir eine
      Zahlung über PayPal leider nicht durchführen.
    </p>
    <p>
      Details zur Zahlung mit Paypal (insbesondere zu etwaig von Paypal
      durchgeführten Bonitätsprüfungen oder Datenweitergaben durch Paypal)
      entnehmen Sie den AGB und den Datenschutzbestimmungen von Paypal unter:
      <a
        href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full"
        target="_blank"
        >https://www.paypal.com/de/webapps/mpp/ua/privacy-full</a
      >
    </p>

    <h2>14. Informationen zu weiteren Funktionen unserer Website</h2>
    <h3>Cookies</h3>
    <h4>a) Allgemein</h4>
    <p>
      Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung
      bestimmter Funktionen zu ermöglichen, verwenden wir auf verschiedenen
      Seiten sogenannte Cookies. Hierbei handelt es sich um kleine Textdateien,
      die auf deinem Endgerät abgelegt werden. Einige der von uns verwendeten
      Cookies werden nach dem Ende der Browser-Sitzung, also nach Schließen
      deines Browsers, wieder gelöscht (sog. Sitzungs-Cookies). Andere Cookies
      verbleiben auf deinem Endgerät und ermöglichen uns oder unseren
      Partnerunternehmen, deinen Browser beim nächsten Besuch wiederzuerkennen
      (persistente Cookies). Wir verwenden Cookies, um Inhalte und Anzeigen zu
      personalisieren und die Zugriffe auf unsere Website zu analysieren.
      Außerdem geben wir Informationen zu deiner Nutzung unserer Website an
      unsere Partner für soziale Medien, Werbung und Analysen weiter.
    </p>
    <h4>b) Rechtsgrundlage</h4>
    <p>
      Die rechtliche Grundlage für die Verwendung von Cookies ist deine
      Einwilligung. Wir setzen ein oder mehrere Cookies nur dann, wenn Du der
      Verwendung von Cookies in dem Cookie-Popup, das beim Besuch unserer
      Website erscheint, zugestimmt hast. Die Rechtsgrundlage ist dann Art. 6
      Absatz 6 1 lit. a DSGVO. Du kannst diese Einwilligung jederzeit mit
      Wirkung für die Zukunft unter Nutzung des folgenden Links widerrufen:
      <a href="#" @click.prevent="$emit('showBanner')">Cookie-Einstellungen</a>.
    </p>
    <h3>
      Da<wbr />ten<wbr />schutz<wbr />er<wbr />klä<wbr />rung für die Nutzung
      von Google Analytics
    </h3>
    <p>
      Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics.
      Anbieter ist die Google Inc. 1600 Amphitheatre Parkway Mountain View, CA
      94043, USA. Google Analytics verwendet sog. „Cookies“. Das sind
      Textdateien, die auf deinem Computer gespeichert werden und die eine
      Analyse der Benutzung der Website durch Dich ermöglichen. Die durch den
      Cookie erzeugten Informationen über deine Benutzung der Website wie:
    </p>
    <ul>
      <li>Browser-Typ/-Version,</li>
      <li>verwendetes Betriebssystem,</li>
      <li>Referrer-URL (die zuvor besuchte Seite),</li>
      <li>Hostname des zugreifenden Rechners (IP-Adresse),</li>
      <li>Uhrzeit der Serveranfrage</li>
    </ul>
    <p>
      werden in der Regel an einen Server von Google in den USA übertragen und
      dort gespeichert.
    </p>
    <h3>IP-Anonymisierung:</h3>
    <p>
      Bei der Aktivierung der IP-Anonymisierung auf dieser Website wird deine
      IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der
      Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
      Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die
      volle IP-Adresse an einen Server von Google in den USA übertragen und dort
      gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese
      Informationen benutzen, um deine Nutzung der Website auszuwerten, um
      Reports über die Websiteaktivitäten zusammenzustellen und um weitere, mit
      der Websitenutzung und der Internetnutzung verbundene Dienstleistungen
      gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google
      Analytics von deinem Browser übermittelte IP-Adresse wird nicht mit
      anderen Daten von Google zusammengeführt.
    </p>
    <h3>Widerspruch:</h3>
    <p>
      Du kannst die Speicherung der Cookies durch eine entsprechende Einstellung
      deiner Browser-Software oder dadurch, dass Du nicht einwilligst,
      verhindern; wir weisen Dich jedoch darauf hin, dass Du in diesem Fall
      gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich
      wirst nutzen können. Du kannst darüber hinaus die Erfassung der durch den
      Cookie erzeugten und auf deiner Nutzung der Website bezogenen Daten (inkl.
      deiner IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
      Google verhindern, indem Du das unter dem folgenden Link verfügbare
      Browser-Plugin herunterlädst und installierst:
      <a target="_blank" href="http://tools.google.com/dlpage/gaoptout?hl=de"
        >http://tools.google.com/dlpage/gaoptout?hl=de</a
      >.
    </p>
    <p>
      Alternativ zum Browser-Add-On, insbesondere bei Browsern auf mobilen
      Endgeräten, kannst Du die Erfassung durch Google Analytics zudem
      verhindern, indem Du auf diesen Link klickst:
      <a href="#" @click.prevent="$emit('showBanner')">Cookie-Einstellungen</a>.
      Es wird ein Opt-out-Cookie gesetzt, das die zukünftige Erfassung deiner
      Daten beim Besuch dieser Website verhindert. Der Opt-out-Cookie gilt nur
      in diesem Browser und nur für unsere Website und wird auf deinem Gerät
      abgelegt. Löschst Du die Cookies in diesem Browser, musst Du das
      Opt-out-Cookie erneut setzen.
    </p>
    <h3>Weitere Informationen:</h3>
    <p>
      Wir nutzen Google Analytics weiterhin dazu, Daten aus Double-click-Cookies
      und auch Google Ads zu statistischen Zwecken auszuwerten. Solltest Du dies
      nicht wünschen, kannst Du dies über den
      <a target="_blank" href="http://www.google.com/settings/ads/onweb/?hl=de"
        >Anzeigenvorgaben-Manager</a
      >
      deaktivieren.
    </p>
    <h3>Facebook-Connect</h3>
    <p>
      Wir bieten Dir die Möglichkeit Dich bei uns über Facebook-Connect
      anzumelden. Rechtsgrundlage hierfür ist deine Einwilligung gem. Art. 6
      Abs. 1 lit. a DSGVO:
    </p>
    <p>
      „Mit dem Klick auf „mit Facebook registrieren“ willige ich ein, dass durch
      Facebook-Connect automatisch Daten an Meine Krone übermittelt und
      anschließend verarbeitet werden. Dies betrifft mein Profilbild, meinen
      Namen und mein Geburtsdatum. Die Datenschutzerklärung habe ich gelesen.
      Diese Einwilligung kann ich jederzeit durch eine E-Mail an
      <a target="_blank" href="mailto:datenschutz@schmidtgruppe.de"
        >datenschutz@schmidtgruppe.de</a
      >
      widerrufen.“
    </p>
    <p>
      Eine zusätzliche Registrierung ist somit nicht erforderlich. Zur Anmeldung
      wirst Du auf die Seite von Facebook weitergeleitet, wo Du Dich mit deinen
      Anmeldedaten von Facebook anmelden kannst. Hierdurch wird dein
      Facebook-Profil und Meine Krone miteinander verknüpft. Durch diese
      Verknüpfung erhalten wir automatisch von Facebook die nachfolgenden
      Informationen:
    </p>

    <ul>
      <li>Name</li>
      <li>Geburtsdatum</li>
      <li>Profilbild</li>
    </ul>
    <p>
      Wir nutzen diese Daten ausschließlich dafür, dass Du am Spielerlebnis
      teilnehmen kannst und für die in dieser Datenschutzerklärung genannten
      weiteren Zwecke.
    </p>
    <p>
      Weitere Informationen zu Facebook-Connect und den
      Privatsphäre-Einstellungen findest Du in den Datenschutzhinweisen und den
      Nutzungsbedingungen von Facebook.
    </p>

    <h3>Google Ads</h3>
    <p>
      Wir haben auf unserer Website Google Ads integriert. Google Ads ist ein
      Dienst von Google Ireland Limited („Google“), Gordon House, Barrow Street,
      Dublin 4, Irland, um Nutzern zielgerichtet Werbung anzuzeigen. Google Ads
      nutzt Cookies und weitere Browser-Technologien um Nutzerverhalten
      auszuwerten und Nutzer wiederzuerkennen.
    </p>

    <p>
      Google Ads sammelt Informationen über das Besucherverhalten auf
      verschiedenen Websites. Diese Informationen werden verwendet, um die
      Relevanz der Werbung zu optimieren. Des Weiteren liefert Google Ads
      gezielt Werbung auf der Grundlage von Verhaltensprofilen und geografischer
      Lage. Dem Anbieter werden Ihre IP-Adresse und weitere
      Identifikationsmerkmale wie dein User-Agent übermittelt.
    </p>

    <p>
      Wenn du bei einem Google Dienst registriert bist, kann Google Ads den
      Besuch deinem Konto zuordnen. Selbst wenn du nicht bei Google registriert
      bist oder du dich nicht eingeloggt hast, ist es möglich, dass der Anbieter
      deine IP-Adresse und weitere Identifikationsmerkmale herausfindet und
      speichert.
    </p>

    <p>
      Die Weitergabe deiner Daten erfolgt in diesem Fall an den Betreiber von
      Google Ads, die Google Ireland Limited, Gordon House, Barrow Street,
      Dublin 4, Irland.
    </p>

    <p>
      Der Einsatz von Google Ads erfolgt auf Grundlage deiner Einwilligung gemäß
      Art. 6 Abs. 1 lit. a. DSGVO und § 25 Abs. 1 TTDSG.
    </p>

    <p>
      Ein etwaiger Drittstaaten-Transfer in die USA erfolgt auf Basis des
      Angemessenheitsbeschluss der Europäischen Kommission zum Datenschutzrahmen
      EU‐USA (EU‐US Data Privacy Framework) vom 10.07.2023.
    </p>

    <p>
      Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns
      beeinflussbar, sondern wird von Google Ireland Limited bestimmt. Weitere
      Hinweise findest du in der Datenschutzerklärung für Google Ads:
      <a href="Google Ads: https://policies.google.com/privacy" target="_blank"
        >https://policies.google.com/privacy</a
      >.
    </p>

    <h3>Google AdSense</h3>

    <p>
      Unsere Website verwendet Google AdSense. Anbieter ist die Google Inc.,
      1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
    </p>

    <p>
      Google AdSense dient der Einbindung von Werbeanzeigen und setzt Cookies.
      Cookies sind kleine Textdateien, die dein Webbrowser auf deinem Endgerät
      speichert, um die Nutzung der Website analysieren. Google AdSense setzt
      außerdem Web Beacons ein. Web Beacons sind unsichtbare Grafiken, die eine
      Analyse des Besucherverkehrs auf unserer Wesite ermöglichen.
    </p>

    <p>
      Durch Cookies und Web Beacons erzeugten Informationen werden an Server von
      Google übertragen und dort gespeichert. Serverstandort sind die USA.
      Google kann diese Informationen an Vertragspartner weiterreichen. Deine
      IP-Adresse wird Google jedoch nicht mit anderen von Ihnen gespeicherten
      Daten zusammenführen.
    </p>

    <p>
      Die Speicherung von AdSense-Cookies erfolgt auf Grundlage von Art. 6 Abs.
      1 lit. a DSGVO.
    </p>

    <p>
      Mit einem modernen Webbrowser kannst du das Setzen von Cookies überwachen,
      einschränken und unterbinden. Die Deaktivierung von Cookies kann eine
      eingeschränkte Funktionalität unserer Website zur Folge haben. Durch deine
      Einwilligung erklärst du dich mit der Bearbeitung der über dich erhobenen
      Daten durch Google in der zuvor beschriebenen Art und Weise sowie dem
      zuvor benannten Zweck einverstanden.
    </p>

    <p>
      Ein etwaiger Drittstaaten-Transfer in die USA erfolgt auf Basis des
      Angemessenheitsbeschluss der Europäischen Kommission zum Datenschutzrahmen
      EU‐USA (EU‐US Data Privacy Framework) vom 10.07.2023.
    </p>

    <h3>Bing Ads</h3>

    <p>
      Wir haben auf unserer Website Bing Ads integriert. Bing Ads ist ein Dienst
      von Microsoft Corporation, um Nutzern zielgerichtet Werbung anzuzeigen.
      Bing Ads nutzt Cookies und weitere Browser- Technologien um
      Nutzerverhalten auszuwerten und Nutzer wiederzuerkennen.
    </p>

    <p>
      Bing Ads sammelt Informationen über das Besucherverhalten auf
      verschiedenen Websites. Diese Informationen werden verwendet, um die
      Relevanz der Werbung zu optimieren. Des Weiteren liefert Bing Ads gezielt
      Werbung auf der Grundlage von Verhaltensprofilen und geografischer Lage.
      Dem Anbieter werden deine IP-Adresse und weitere Identifikationsmerkmale
      wie dein User-Agent übermittelt.
    </p>

    <p>
      Die Weitergabe Ihrer Daten erfolgt in diesem Fall an den Betreiber von
      Bing Ads, die Microsoft Corporation, One Microsoft Way Redmond, WA
      98052-6399, United States.
    </p>

    <p>
      Der Einsatz von Bing Ads erfolgt auf Grundlage deiner Einwilligung gemäß
      Art. 6 Abs. 1 lit. a. DSGVO und § 25 Abs. 1 TTDSG.
    </p>

    <p>
      Ein etwaiger Drittstaaten-Transfer in die USA erfolgt auf Basis des
      Angemessenheitsbeschluss der Europäischen Kommission zum Datenschutzrahmen
      EU‐USA (EU‐US Data Privacy Framework) vom 10.07.2023.
    </p>

    <p>
      Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns
      beeinflussbar, sondern wird von Microsoft Corporation bestimmt. Weitere
      Hinweise finden Sie in der Datenschutzerklärung für Bing Ads:
      <a
        href="https://privacy.microsoft.com/de-de/privacystatement"
        target="_blank"
        >https://privacy.microsoft.com/de-de/privacystatement</a
      >
    </p>

    <h3>Facebook Pixel</h3>

    <p>
      Wir verwenden Facebook Pixel von Meta Platforms Ireland Limited, 4 Grand
      Canal Square, Grand Canal Harbour, Dublin 2, Ireland, um sogenannte Custom
      Audiences zu erstellen, also Besuchergruppen unseres Onlineangebotes zu
      segmentieren, Conversion-Rates zu ermitteln und diese anschließend zu
      optimieren. Dies geschieht insbesondere dann, wenn du mit Werbeanzeigen,
      die wir mit Meta Platforms Ireland Limited geschaltet haben, interagierst.
    </p>

    <p>
      Der Einsatz von Facebook Pixel erfolgt auf Grundlage deiner Einwilligung
      gemäß Art. 6 Abs. 1 lit. a. DSGVO und § 25 Abs. 1 TTDSG.
    </p>

    <p>
      Ein etwaiger Drittstaaten-Transfer in die USA erfolgt auf Basis des
      Angemessenheitsbeschluss der Europäischen Kommission zum Datenschutzrahmen
      EU‐USA (EU‐US Data Privacy Framework) vom 10.07.2023. Die konkrete
      Speicherdauer der verarbeiteten Daten ist nicht durch uns beeinflussbar,
      sondern wird von Meta Platforms Ireland Limited bestimmt. Weitere Hinweise
      finden Sie in der Datenschutzerklärung für Facebook Pixel:
      <a href="https://www.facebook.com/privacy/explanation" target="_blank"
        >https://www.facebook.com/privacy/explanation</a
      >.
    </p>
  </div>
</template>

<script>
export default {
  name: "PrivacyTextDe",
};
</script>
