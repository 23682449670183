<template>
  <b-col id="imprint-container" cols="12" class="content-row px-3 pb-3">
    <BackArrow @back="$emit('back')" />

    <b-row no-gutters> <component :is="localeText"></component> </b-row
  ></b-col>
</template>

<script>
import BackArrow from "@/components/legal/back-arrow.vue";
import ImprintTextDe from "@/components/legal/imprint-text_de.vue";
import ImprintTextEn from "@/components/legal/imprint-text_en.vue";

export default {
  name: "ImprintContainer",
  components: { BackArrow, ImprintTextDe, ImprintTextEn },
  computed: {
    localeText() {
      if (this.$store.state.locale === "de") {
        return "ImprintTextDe";
      } else {
        return "ImprintTextEn";
      }
    },
  },
};
</script>
