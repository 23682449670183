<template>
  <div>
    <h1>General Terms and Conditions for kleinekrone.de (hereafter KK)</h1>
    <h2>1. Scope of Application</h2>
    <p>
      This offer is exclusivley aimed at adults of at least 18 years of age. Our
      Social Casino Games are for entertainment purposes only and have no
      influence on potential future success with real money gambling.
    </p>
    <p>
      "Meine Krone GmbH" (hereinafter MK) offers an entertainment platform on
      the internet at
      <a :href="mainUrl">www.kleinekrone.de</a> (hereinafter "platform").
      Various games (online games), as well as additional entertainment and
      services ("services") are offered on the platform, including, for example,
      the creation of a profile, joining an online team, participation in chats
      where applicable, social media (in particular Facebook, Instagram, forums
      and Twitter), the introduction of media content such as photos, texts and
      games, as well as the acquisition of virtual content, objects or other
      services. The participants of the games of MK and the users of the
      platform are hereinafter referred to as "users".
    </p>
    <p>
      As far as these General Terms and Conditions (hereinafter referred to as
      "GTC") refer to "entertainment" or "services", this refers to the
      entertainment and services offered by MK. For the business relations
      between MK and the users we provide our General Terms and Conditions which
      are the basis for the provision of all services provided on the platform
      as well as the privacy policy and the imprint which are an integral part
      of the GTC.
    </p>
    <p>
      The contractual partner is Meine Krone GmbH. GTC of the user will
      explicitly not become part of the contract, unless MK specifically agrees
      to them in writing.
    </p>
    <p>
      The following GTC regulate the use of the platform, games and services.
      The use can be reached primarily by PC on the internet via telephone,
      ISDN, DSL or another equivalent connection. In addition, the use is
      possible via various other internet-capable end devices (via Appstore and
      Playstore), whereby the range of functions or the presentation may be
      displayed or limited differently.
    </p>
    <p>
      Subject of these terms and conditions are not any questions which may
      arise in connection with the provision of the connections as well as the
      connection of the websites and online games and the software of third
      parties (browser, access software), as these services are not provided by
      MK.
    </p>
    <h3>1.1 Users</h3>
    <p>
      1.1.1 MK offers the games and services exclusively to consumers in terms
      of § 13 of the German Civil Code (BGB). The use of the games and services
      for profit or other commercial purposes is excluded.
    </p>
    <p>
      1.1.2 Persons who have reached the age of 18 at the time of registration
      are entitled to use the software.
    </p>
    <p>
      1.1.3 By registering to use and/or installing the Games or Services, the
      User expressly affirms that they are of lawful age and have the legal
      capacity to contract.
    </p>
    <p>
      1.1.4 Users from Cuba, Iran, North Korea, Sudan and Syria are excluded
      from using the games.
    </p>
    <h3>
      1.2 Subject Matter of the Contract, Use and Modification of the Games and
      Services
    </h3>
    <p>
      1.2.1 MK enables the users to participate in the games and services within
      the scope of the existing technical and operational possibilities.
    </p>
    <p>1.2.2 Participation in the games is for entertainment purposes only.</p>
    <p>
      1.2.3 With regard to the services MK provides certain information on the
      platform and enables the users, among others, to put information on the
      net, if necessary to create individual personal profiles which can be
      viewed by game users, service users and third parties. Furthermore, the
      user can communicate with third parties via team chat, publish comments
      and give ratings on games, other users, third parties or transactions.
    </p>
    <p>
      1.2.4 The games and services are services of MK which consist in the
      provision of the software for the use of the platform for the duration of
      the contract. The use is enabled by MK providing the application necessary
      for participation online on the respective URLs of the games or services.
      MK reserves the right to offer the games also - if necessary with a
      different scope of functions - on data carriers which can be purchased.
      The respective sale of games is not subject to these terms and conditions.
    </p>
    <p>
      1.2.5 Participation in the Games and use of the Services is only open to
      persons who have previously created a customer account (hereinafter
      "Account") by means of registration. The registration is made either
      online by filling out the registration form on the Platform or via another
      website or landing page with a corresponding registration function, via
      the registration function of a social network, such as Facebook Connect,
      or via the ID of an Appstore/Playstore. MK reserves the right to make the
      activation of the account dependent on the activation of a confirmation
      link, which will be sent to the user by email. An account can be created
      either on the platform or on the respective URL of the game. An account
      created on the platform entitles the user to use the games and services
      under the conditions of paragraph 1.1.
    </p>
    <p>
      1.2.6 Registration, i.e. the application for opening an account, is done
      by electronically filling in a form and entering various information. The
      use of the games and services is enabled from the moment when MK has
      created an account for the user.
    </p>
    <p>
      1.2.7 A user is only authorized to create an account on the platform. For
      all other accounts MK reserves the right to block them as inadmissible
      accounts. The use of an account on different devices is allowed.
    </p>
    <p>
      MK is also free to block temporarily or completely any user who cancels
      payments, enters wrong payment data, transmits wrong data during
      registration, is insulting, defaming, racist or discriminating in any
      other way about or towards other users, MK employees, MK or third parties,
      be it in chat, via e-mail, in social media or in any other way and place,
      and to exclude him from the games and services. This also applies to all
      other violations of the general terms and conditions and the rules of the
      game or conditions of participation. Any claims against MK for
      participation in the game, winnings, prizes, accounts, credit balances,
      payments made and all further claims resulting from this expire for the
      user in case of violation of the GTC.
    </p>
    <p>
      1.2.8 After creating the account, the User can participate in the
      respective game or service by accessing the platform or the corresponding
      URL and then logging in.
    </p>
    <p>
      1.2.9 The use of the games and services is only permitted by means of an
      internet browser or special tools provided or expressly permitted by MK
      ("Prohibition of using unauthorized scripts"). This means in particular:
      The use of programs which generate an excessive load on the server is
      prohibited. The use of software for the systematic or automatic control of
      the games or individual game functions (bots, macros), for the
      reproduction or evaluation of the games, game elements or the content
      posted on the platform is not permitted. MK is free to block users who try
      or execute this either temporarily or permanently.
    </p>
    <p>
      1.2.10 It is forbidden to use errors in programming, operation,
      transmission or other causes for your own purposes. A user must report
      detected bugs to the support immediately after detection. Advantages for
      the user resulting from errors and bugs are null and void and do not
      constitute a claim for the user. MK may decide at its own discretion how
      to deal with these advantages for the user.
    </p>
    <p>
      1.2.11 The user has no right to open an account or to publish any content
      on the platform.
    </p>
    <p>
      1.2.12 The respective current technical and other game and participation
      requirements for the Services can be found, where applicable, in further
      information on the platform or the websites of the games.
    </p>
    <p>
      1.2.13 The games and services are continuously updated, adapted, expanded
      and changed. Accordingly, the User shall only be granted one right of use
      for the respective game and the Services in the respective current
      version.
    </p>
    <p>
      1.2.14 The use of the games in their basic version is free of charge.
      However, the User may purchase various services and virtual goods (see
      Section 7). Unless agreed in the descriptions of the respective services,
      the use of the services is free of charge.
    </p>
    <p>
      1.2.15 The User shall have no claim to the maintenance of the Games or
      Services in the version existing at the time of conclusion of the
      contract. MK reserves the right to stop the operation of a game or service
      at any time without giving reasons. Claims of the user are excluded,
      unless explicitly provided otherwise in these GTC.
    </p>
    <h2>2. Offer of Contract and Conclusion of Contract</h2>
    <p>
      2.1 By completing the registration form, the User submits a binding offer
      to conclude a game and service usage agreement (also referred to as "User
      Application"). For this purpose, all data fields of the registration form
      marked as necessary must be filled in completely and correctly.
    </p>
    <p>
      2.2 The contract between MK and the user is concluded with acceptance of
      the user application by MK. The acceptance can be made explicitly or by
      the first fulfillment action, especially by the provision of the game or
      service.
    </p>
    <p>
      2.3 As far as the creation of an account is necessary for the use of a
      game or service, the receipt of the user application will be confirmed by
      MK immediately electronically to the e-mail address given by the user. The
      confirmation of receipt does not represent a binding acceptance of the
      user application. However, the confirmation of access can be combined with
      the declaration of acceptance.
    </p>
    <h2>3. Cancellation Policy</h2>
    <p class="bold">
      If the user is a consumer with habitual residence or domicile in the
      European Union, he has a legal right of revocation in accordance with the
      following revocation instructions:
    </p>
    <h3>3.1 Widerrufsbelehrung</h3>
    <p class="text-uppercase bold">Right of withdrawal</p>
    <p class="bold">
      You have the right to cancel this contract within fourteen days without
      giving reasons. The revocation period is fourteen days from the date of
      conclusion of the contract. In order to exercise your right of revocation,
      you must inform us of your decision to revoke this contract by means of a
      clear statement (e.g. a letter, fax or e-mail sent by post). You may use
      the attached sample revocation form (see section 3.3.), which is not
      mandatory. In order to comply with the revocation period, it is sufficient
      that you send the notification of the exercise of the right of revocation
      before the end of the revocation period.
    </p>
    <p class="text-uppercase bold">Consequences of Cancellation</p>
    <p class="bold">
      If you revoke this Agreement, we shall reimburse you for all payments we
      have received from you, including delivery costs (with the exception of
      additional costs resulting from the fact that you have chosen a different
      type of delivery than the cheapest standard delivery offered by us),
      immediately and at the latest within fourteen days from the day on which
      we receive notification of your revocation of this Agreement. For this
      refund, we will use the same means of payment that you used for the
      original transaction, unless expressly agreed otherwise with you; in no
      event will you be charged for this refund.
    </p>
    <h3>3.2 Premature expiration of the right of cancellation</h3>
    <p class="bold">
      Your right of revocation expires prematurely in the case of a contract for
      the delivery of digital content not contained on a physical data carrier
      if you have expressly agreed that we will begin to execute the contract
      before the end of the revocation period and you have confirmed that you
      are aware that you will lose your right of revocation by giving your
      consent when the execution of the contract begins. The right of revocation
      expires if the virtual goods are used or consumed, e.g. through use in a
      game, the outcome of the game is irrelevant in this case. Once used, the
      virtual goods are considered to be used up. The purchased goods are always
      used first.
    </p>
    <p class="text-uppercase bold">End of the cancellation policy</p>
    <h3>3.3 Sample cancellation form</h3>
    <p>
      If you want to cancel the contract, please fill out this form and send it
      back.
    </p>
    <p>
      To:<br />
      Meine Krone GmbH <br />
      Druffels Weg 1 <br />48653 Coesfeld<br />
      E-Mail: <a href="mailto:kontakt@sgservice.de">kontakt@sgservice.de</a
      ><br />
    </p>
    <p>
      I/we (*) hereby cancel the contract concluded by me/us (*) for the
      purchase of the following goods (*)/the provision of the following service
      (*)
    </p>
    <ul>
      <li>Ordered on (*)/received on (*)</li>
      <li>Name of the consumer(s)</li>
      <li>Address of the consumer(s)</li>
      <li>
        Signature of the consumer(s) (only in case of communication on paper)
      </li>
      <li>Date</li>
    </ul>
    <p>(*) Delete as applicable.</p>
    <!-- <p>
      Das Musterwiderrufsformular kann zudem hier abgerufen werden.
    </p> -->
    <h2>4. Accessibility</h2>
    <p>
      MK guarantees an availability of the games and services of ninety percent
      on an annual average. Excluded from this are times in which the servers of
      the platforms, or of the individual games, cannot be reached via the
      internet due to technical or other problems which are not in the sphere of
      influence of MK (force majeure, fault of third parties etc.) as well as
      times in which routine maintenance work is carried out. The liability of
      MK for an inaccessibility of the games and services in case of intent and
      gross negligence remains unaffected. MK can restrict the access to the
      services if the security of the network operation, the maintenance of the
      network integrity, especially the avoidance of serious disturbances of the
      network, the software or stored data require this.
    </p>
    <h2>
      5. Access and acknowledgement of the GTC, changes and further
      notifications, contact by the user
    </h2>
    <p>
      5.1 These GTCs apply to any log-in to the Platform, in particular to any
      participation in the Games or use of the Services. The GTC may be printed
      out or stored on a permanent data carrier by the User prior to sending the
      User Application.
    </p>
    <p>
      5.2 MK reserves the right to change or amend the GTC with effect for the
      future at any time, as far as this seems necessary and the user is not
      disadvantaged by this against good faith. An amendment may be necessary in
      particular, (a) to make adjustments to a change in the legal situation,
      (b) in case of changes in the highest court jurisdiction, (c) due to
      technical necessity, (d) to maintain operations, (e) in case of changes in
      market conditions, (f) for the benefit of the user.
    </p>
    <p>
      5.3 An amendment or supplement to the GTC will be announced in a suitable
      manner in text form at least four weeks before it takes effect. As a rule,
      notification of the amendment of the GTC shall be made by e-mail or on the
      platform or website of the Games or in the Games, but in any case by a
      highlighted announcement the next time the User logs in.
    </p>
    <p>
      5.4 The user has the right to object to a change or addition of these
      terms and conditions within four weeks after publication and the
      possibility to take notice of it towards MK. In case of a timely objection
      both parties are entitled to terminate the contract extraordinarily
      according to the termination provisions of this contract. Other
      termination rights remain unaffected. In this case, any service fees paid
      in advance for the termination period will be refunded to the user
      proportionately; further claims are excluded. If the User does not object
      within the four-week objection period or if he continues to use the
      services thereafter, the change or amendment shall be deemed to have been
      accepted and shall become an integral part of the contract. The provisions
      of item 5.1. shall apply accordingly.
    </p>
    <p>
      5.5 MK will especially point out to the user in the information about the
      changes or amendments of the GTC the possibility of objection and
      termination, the period of time and the legal consequences, especially
      with regard to an omitted objection.
    </p>
    <p>
      5.6 MK will communicate with the user via e-mail as a rule, unless
      otherwise determined by these GTC or other agreements with the user. The
      user will ensure that he will be reached by e-mails sent by MK to the
      e-mail address given by him during registration or later communicated to
      MK. He will ensure this among other things by appropriate settings of the
      spam filter and will check this address regularly. Otherwise MK reserves
      the right to choose another suitable form of correspondence.
    </p>
    <p>
      5.7 Whenever the user contacts MK, he shall state to which of the games or
      services and to which game account or service account his request refers.
    </p>
    <h2>6. Game instructions, game rules, conditions of participation</h2>
    <p>
      6.1 In addition to these GTCs, separate game rules or conditions of
      participation may apply for the respective game or the use of a service.
      Unless expressly stated otherwise, in the event of contradictions between
      these GTC and the rules of the game or the conditions of participation,
      the provisions of these GTC governing the circumstances giving rise to the
      contradiction shall take precedence. For individual games, special
      variants and/or components of the games and individual service offers on
      the Platform, special terms of use may also apply. The User shall be
      informed of these in a suitable form before making use of the respective
      offer.
    </p>
    <p>
      6.2 The User is aware that he/she may play together with other users or
      communicate on the platform with different service users. In order to
      enable a fruitful interaction, it is necessary to adhere to rules.
    </p>
    <p>
      6.3 With regard to section 6.2, the User shall refrain from doing anything
      that interferes with the operation of the games and services and the
      thriving interaction.
    </p>
    <h2>7. Free playing, payments, terms of payment, delay</h2>
    <h3>7.1 Online Games</h3>
    <h4>7.1.1 Basic Version</h4>
    <p>
      MK provides the games to the users in principle from the time of creation
      of an account (see 1.2 above). In this case, the user is first provided
      with a basic version. The creation of the account and the use of the basic
      version are free of charge. The basic version is (without prejudice to the
      right of MK to discontinue the games) not limited in time and fully
      playable. In the basic version, however, not all features may be available
      to the user. Some of these features can be unlocked by the User with the
      title of the game.
    </p>
    <h4>7.1.2 Features</h4>
    <p>
      The user has the opportunity to receive virtual goods and, if applicable,
      features against payment of a fee, as well as to acquire time advantages
      that are not available or only available to a limited extent in the basic
      version. Which virtual goods and features are offered at which tariff,
      which functions they have and which requirements they require can be found
      in the information of the game. Depending on the specific feature and
      tariff, these may be one-off payments, topping up a credit balance (Gold
      or Crowns) that can be used for certain functions in the respective game
      in accordance with its rules, or payments that must be made for a certain
      period of time (for example, day, week, month, quarter, half-year, year).
    </p>
    <p>
      All prices are gross prices, i.e. including any applicable taxes. The
      games are constantly being developed. MK therefore reserves the right to
      offer new features at any time. In the course of adaptation and further
      development of the games MK also reserves the right to discontinue
      individual features or to offer them in the basic version (see above
      7.1.1). Claims of the user for reimbursement of the fees paid for these
      features are excluded.
    </p>
    <p>
      In case of permanent discontinuation of an MK game, section 1.2.15 shall
      apply.
    </p>
    <h4>7.1.3 Subscription / Automatic renewal</h4>
    <p>
      If payments for Premium Features are to be made for a certain period of
      time, the User shall take out a subscription which shall be automatically
      renewed unless the User terminates it in due time pursuant to Section 8.2
      at the end of the respective period of time. The terms of the subscription
      can be found in the game rules or terms of participation applicable to the
      respective game.
    </p>
    <h4>7.1.4 Terms of payment, maturity</h4>
    <p>
      MK is entitled to demand service fees in advance for the use of the
      features or certain virtual goods (see above 7.1.2). The service fees are
      due upon conclusion of the contract and will be debited from the bank
      account or credit card indicated by the user, unless the user chooses
      another method of payment (e.g. Appstore, Paypal). The amount to be
      collected is shown on the credit card statement or bank statement as
      www.kleinekrone.de " or "Meine Krone", or "Meine Krone GmbH" or "Adyen
      Meine Krone". When using alternative payment methods, the designation may
      differ.
    </p>
    <h4>7.1.5 Adjustments of the fees</h4>
    <p>
      MK is entitled at any time to reduce or increase prices permanently or for
      a limited time as well as to offer new products, services or billing
      modalities permanently or for a limited time. The contractual relationship
      will then be continued at the changed conditions/prices without prejudice
      to the rights of the user according to item 8.2 and item 8.3.
    </p>
    <h4>7.1.6 Adjustments of fees for subscriptions</h4>
    <p>
      MK is entitled to adjust the fees for the contractually agreed services in
      case of subscriptions according to the following conditions: A price
      change will be announced to the user at least four weeks before it becomes
      effective by written declaration or by e-mail. The user has a four-week
      extraordinary right to terminate the contract at the time of the price
      change from the date of the announcement. MK refers to this right in the
      announcement declaration. If the user does not exercise this right, the
      price change is considered as approved. Price reductions are generally
      valid from the announced date for the next billing period.
    </p>
    <h3>7.2 Default</h3>
    <p>
      In case of default MK is entitled to demand interest in the respective
      legally prescribed amount. In case of default MK is further entitled to
      stop the services or to block the accounts of the users immediately. For
      the period of the blockage there is no service charge for any closed
      subscriptions.
    </p>
    <h3>7.3 Chargebacks, cancellation fee</h3>
    <p>
      If MK should incur cancellation fees in case of cancelled transactions due
      to a fault of the user or due to circumstances for which the user is
      responsible (this includes e.g. insufficient account coverage), the user
      shall bear the costs actually incurred or passed on to MK due to this. MK
      is entitled to claim these costs together with the original fee from the
      user. In all cases the user is free to prove that a damage did not occur
      or is considerably lower.
    </p>
    <h3>7.4 No profit guarantee</h3>
    <p>
      MK does not guarantee the users any profits. In particular the users have
      no claim to a payout of a prize or winnings. The users play with virtual
      goods and virtual currencies for entertainment purposes. A claim for
      payment of a prize within a free raffle can only exist if such a prize was
      explicitly offered by MK within a free raffle. A claim also does not exist
      if MK determines that the possible claim for a prize could have arisen due
      to manipulations - technical or legal or due to any criminal manipulation
      in general. By participating in the games of MK the user agrees that MK
      can demand a comprehensive investigation at any time regarding the
      legality of a claim for a prize and in the meantime MK can refuse the
      payment of the prize without separate consent of the user. The claim to
      the prize is also forfeited if the user behaves contrary to the rules of
      the game.
    </p>
    <h3>7.5 No offsetting, rights of retention, prohibition of assignment</h3>
    <p>
      The user can only offset against claims of MK with undisputed or legally
      binding counterclaims. The user can only exercise a right of retention if
      his counterclaim is based on the same contractual relationship. The
      assignment of claims of the user against MK to third parties is excluded.
    </p>
    <h3>7.6 Service</h3>
    <p>
      Unless otherwise agreed in the service description, services are free of
      charge. Otherwise, Sections 7.1 to 7.5 shall apply mutatis mutandis to
      services subject to charges.
    </p>
    <h2>8. Duration, Cancellation</h2>
    <h3>8.1 Duration</h3>
    <p>
      The contracts between the user and MK are concluded for an indefinite
      period of time unless otherwise specified in the concrete offer of MK.
    </p>
    <h3>8.2 Proper Cancellation</h3>
    <p>
      Each party is entitled to terminate the contractual relationship without
      giving reasons by giving notice as follows:
    </p>
    <p>
      8.2.1 If a limited term has not been agreed for a contract, both parties
      are entitled to cancel the contract at any time with immediate effect.
    </p>
    <p>
      8.2.2 If a specific term has been agreed for a contract (for example: a
      game or service usage contract or a contract) for the use/provision of
      Premium Features (see also Sections 7.1.2 and 7.1.3), this contract shall
      be automatically extended upon expiration by the same term until the User
      terminates the subscription at the end of the respective term.
    </p>
    <h3>8.3 Termination for good reason</h3>
    <p>
      8.3.1 The right of the parties to terminate at any time for good reason
      shall remain unaffected by the above provisions.
    </p>
    <p>
      8.3.2 If MK is responsible for the extraordinary termination, the user
      will be refunded proportionally the fees (especially for Premium Features)
      which he may have paid in advance beyond the termination period. Further
      claims of the user are excluded, unless otherwise agreed in these GTC.
    </p>
    <p>
      MK is especially, but not exclusively, entitled to terminate the contract
      for good cause if:
    </p>
    <ol>
      <li>
        the user culpably violates laws, these GTC, the rules of the game and/or
        the conditions of participation and repeatedly does not behave according
        to the rules in the same or a similar way despite a warning;
      </li>
      <li>
        the User defaults on payment of the fees with an amount of at least EUR
        10.00 and does not pay despite two reminders;
      </li>
      <li>
        third parties (e.g. operators of social networks), via whose
        registration function the user gains access to his account with the
        provider, request the provider to delete user data and/or comparable
        measures; if an alternative granting of access would be unreasonable for
        the provider;
      </li>
      <li>
        third parties (e.g. operators of social networks), via whose
        registration function the user gains access to his account with the
        provider, restrict the provider's access to data, if an alternative
        granting of access would be unreasonable for the provider.
      </li>
    </ol>
    <p>
      8.3.3 In the event of serious violations, immediate termination is
      permissible without the need for a prior warning. A serious violation is a
      violation where MK cannot be expected to adhere to the contract. This is
      usually the case,
    </p>
    <ol>
      <li>if the user violates criminal laws</li>
      <li>
        if the user uses the account, a game or services in an illegal manner;
      </li>
      <li>
        if the user provides false data when registering or paying for paid
        services
      </li>
    </ol>
    <p>
      8.3.4 In case of a justified notice of termination by MK for an important
      reason MK is entitled to claim an amount of 50% of the sum of all fees
      which the user would have had to pay in case of a simultaneous termination
      in due time during the contract period (for services not yet provided by
      the provider, especially for virtual currencies or premium features
      already ordered). The User's right to prove that no damage or a
      significantly lower damage has been incurred remains unaffected. As far as
      the user has already received the service to be provided by MK, there is
      no claim for reimbursement. In particular there will be no refund for
      virtual currencies or features which the user ordered from MK and already
      received or used.
    </p>
    <p>
      8.3.5 If MK is responsible for the extraordinary termination and the user
      still has virtual currency for a game or services on his account, MK will
      credit the user with the virtual currency for a game or services desired
      by the user which are offered by MK. A repayment in cash is excluded,
      unless the credit cannot be expected from the user exceptionally under
      consideration of the mutual interests, e.g. because no equivalent or
      similar game or services are offered. In this case MK will reimburse the
      user the value of any virtual currency still on his account in the then
      still existing amount of the amount actually deposited by the user.
      Further claims of the user are excluded, unless these GTC determine
      otherwise.
    </p>
    <p>
      8.3.6 MK has a special right of termination regarding the registration of
      the user for games or services in case MK loses the right to operate the
      games or services, e.g. due to regulatory reasons or due to termination of
      the respective license agreement between MK and the respective licensor.
      In this case MK can extraordinarily terminate all contracts related to the
      operation and use of the game or services (e.g. contract of use, contract
      for the provision and use of virtual currency or premium features) at the
      time of discontinuation of the game operation. In such cases, the
      provisions of Clause 8.3.5 Other termination rights shall remain
      unaffected.
    </p>
    <p>
      8.3.7 Virtual currency or Premium Features granted free of charge are
      generally excluded from reimbursement.
    </p>
    <h3>8.4 Termination in text form</h3>
    <p>
      Any notice of termination must be in text form (e.g. e-mail) to be
      effective.
    </p>
    <h2>
      9. Duties and other obligations of the user, as well as liability of the
      user for information, copyrights
    </h2>
    <h3>9.1 Duties</h3>
    <p>
      The User's main obligation is to pay a designated fee, unless the User
      uses the free basic version of the Game or Services (see Section 7 above).
      Furthermore, the main service obligation of the user is the obligation to
      comply with the applicable rules of the game as well as the obligation to
      provide correct and complete data which MK requests from the user at the
      conclusion of the contract or in the course of the contractual
      relationship. Therefore, the user assures that the information about his
      person and other circumstances relevant to the contract (especially
      payment data) given by him in the context of the offer of contract or the
      conclusion of the contract or in the continuation of the contractual
      relationship are complete and correct. The user commits himself to inform
      MK immediately about changes of the data; upon request of MK the user has
      to confirm the data. The user is obliged to follow the rules of the game.
      In case of a violation MK is entitled to block the contractual services
      and deliveries immediately and without granting a period of notice and to
      terminate the contract.
    </p>
    <h3>9.2 Installation of software</h3>
    <p>
      MK is not liable for damages or loss of data, which may occur on the
      user's computer due to the installation of software, which does not
      originate from MK.
    </p>
    <h3>9.3 Further obligations of the user</h3>
    <p>
      9.3.1 MK provides the games and/or services online for use via a web
      browser. The software required locally on the user's computer (in
      particular operating system and web browser, if applicable also plug-ins
      such as Flash or Java) will neither be provided nor installed by MK. MK
      does not provide any support for these either. It is the user's
      responsibility to keep the computer in a state which allows the use of the
      games of MK. Accordingly MK does not provide any technical support for the
      installation of the locally required software.
    </p>
    <p>
      9.3.2 The user is obliged to keep the access data (login data, passwords
      etc.) received by MK for the purpose of access to its games or services
      strictly confidential. The user will inform MK immediately as soon as he
      gets knowledge of it or suspects that unauthorized third parties have
      knowledge of the access data. For reasons of preservation of evidence the
      user is recommended to do this in writing (e.g. by e-mail). If a third
      party uses an account after having obtained the access data because the
      user has not sufficiently secured them against unauthorized access, the
      user has to be treated as if he himself had acted because of the risk of
      confusion created by him about who has acted under the respective account
      and who can be claimed in case of a breach of contract or other violation
      of rights. MK is entitled to consider any access with the user's data as
      access of the user himself. MK points out that passwords should be changed
      regularly for security reasons. The user is solely responsible for the use
      of his account.
    </p>
    <p>
      9.3.3 In case of a justified suspicion that access data became known to
      unauthorized third parties, MK is entitled but not obliged for security
      reasons to independently change the access data without prior notice or to
      block the use of the account at its own discretion. MK will immediately
      inform the authorized user about this and upon request within a reasonable
      period of time the new access data will be communicated to the authorized
      user. The user has no right to demand that the original access data be
      restored.
    </p>
    <p>
      9.3.4 The User is not entitled to sell or otherwise pass on or lend his
      account to third parties. The User is also prohibited from selling,
      assigning or commercially using any services, features and virtual goods
      to third parties.
    </p>
    <p>
      9.3.5 MK protects its systems against virus attack. Nevertheless a virus
      attack can never be completely excluded. In addition, it may happen that
      unauthorized third parties send e-mails using the name of MK without their
      consent, which for example contain viruses or so-called spyware or link to
      web contents containing viruses or spyware. MK has no influence on this.
      The user will therefore check all incoming e-mails sent by MK or allegedly
      in their name for virus attack. This also applies to e-mails from other
      users of the games or services.
    </p>
    <p>
      9.3.6 The user will follow the instructions of MK and its employees as
      well as its vicarious agents and assistants. This applies in particular
      also to the instructions of the administrators and moderators of a forum
      possibly belonging to the respective game or service.
    </p>
    <p>
      9.3.7 The User undertakes not to use the account, player name or password
      of another user under any circumstances.
    </p>
    <h3>
      9.4 Obligations of the user with regard to any information posted by him
    </h3>
    <p>
      9.4.1 The User shall carefully select the information he publishes on the
      Platform and makes available to other Users.
    </p>
    <p>
      9.4.2 The User undertakes not to disseminate within the scope of the
      Services any content (e.g. pictures, videos, links, names, words) with
      advertising, political, religious, insulting, harassing, violent, sexist,
      pornographic or other morally reprehensible or offensive, in particular
      racist, as well as right- or left-wing extremist content, persons or
      representations. Furthermore, the user undertakes not to use any legally
      protected terms, names, pictures, videos, music, games or other materials.
      In case of doubt the user has to remove contents complained about by MK
      immediately. MK also has the right to remove them itself. The user will
      always observe all relevant laws and legal regulations, especially
      regarding the protection of minors, data protection, protection of
      personal rights, protection against insult, copyrights, trademark rights
      etc.
    </p>
    <p>
      9.4.3 The User is not authorized to use the Services for illegal or
      unauthorized purposes. In particular, he is not authorized to use the
      player names or e-mail addresses of other users without their prior
      consent for the sending of any unsolicited e-mails, advertising messages
      or for any other commercial or business purposes.
    </p>
    <p>
      9.4.4 A culpable violation of the aforementioned obligations entitles MK
      to delete the information posted by the user itself.
    </p>
    <p>
      9.4.5 In particular MK is entitled to delete posted information completely
      or partially if there are concrete indications for a violation of these
      GTC, as well as the instructions and rules of the respective services or
      if these are otherwise illegal. This is for example the case for
      information which:
    </p>
    <ol>
      <li>is obviously offensive, racist, fanatical, glorifies violence</li>
      <li>
        harasses another person or is insulting, threatening, obscene,
        defamatory or slanderous
      </li>
      <li>
        are sexist, pornographic or otherwise harmful to minors or contain a
        link to an adult website;
      </li>
      <li>
        contains false or misleading information; promotes illegal conduct;
      </li>
      <li>
        constitute an illegal or unauthorized copy or distribution of the
        copyrighted work, e.g. by providing illegal computer programs or links
        to illegal computer programs, information to circumvent copy protection
        devices and illegal music copies or links to illegal music copies or
        otherwise violate copyright law;
      </li>
      <li>
        sending "junk mail", "chain letters", unsolicited mass messages or
        "spamming";
      </li>
      <li>
        promoting criminal activities or schemes or containing or inciting
        instructions for illegal activities, or including, but not limited to,
        containing information to manufacture or purchase weapons, child
        pornography, fraud, drug trafficking, gambling, stalking, spamming,
        distribution of computer viruses and other harmful files, copyright
        infringement, patent infringement, or theft of trade secrets;
      </li>
      <li>
        ask other users to provide personal data for commercial or unlawful
        purposes or to provide login information;
      </li>
      <li>
        involve commercial activities or sales, such as contests, sweepstakes,
        barter, advertising, pyramid schemes;
      </li>
      <li>contain an image of another person without their consent.</li>
    </ol>
    <p>
      9.4.6 There is no claim to the recovery of deleted information. In
      addition, MK is entitled to exclude the user from further participation in
      the affected services and to terminate the user's account without notice
      in case of violation of the aforementioned regulations. The assertion of
      further claims by MK, especially claims for damages, is expressly
      reserved.
    </p>
    <p>
      9.4.7 The user will inform MK in case he gets knowledge of an abusive use
      of the services by third parties or other users (e.g. distribution and
      sending of contents which are prohibited according to paragraph 9.4.5). To
      ensure a more effective intervention the user is asked to do this in text
      form (e.g. by e-mail).
    </p>
    <h3>9.5 Liability of any information posted by user</h3>
    <p>
      9.5.1 The user is responsible for texts, files, images, photos, videos,
      sounds, musical works, copyright works or other materials, information,
      etc. (hereinafter referred to as "posted information"), which he makes
      available on the platform or exchanges with other users. MK neither adopts
      these contents nor agrees to them.
    </p>
    <p>
      9.5.2 MK has no control over the information posted on the platform. MK
      does not check the information posted before its publication. However, if
      MK has or receives knowledge of unlawful posted information, it will
      delete it immediately.
    </p>
    <p>
      9.5.3 Any liability of MK for the posted contents, especially for the
      correctness, completeness or reliability of contents, materials or
      information is excluded.
    </p>
    <h3>9.6 Copyrights</h3>
    <p>
      9.6.1 All rights to the information posted by the user remain with the
      user. By posting information on the platform or its games or services the
      user grants MK a non-exclusive, royalty-free and at any time freely
      revocable license to publicly present, publicly display, reproduce and
      distribute these contents on the platform or the game pages.
    </p>
    <p>
      9.6.2 MK has no further rights of use for the information provided by the
      user. MK is not entitled to distribute posted information outside the
      platform or the respective site of the games and services.
    </p>
    <p>
      9.6.3 Information posted on the platform by the user can be viewed by
      third parties worldwide via the Internet. By providing the information,
      the user declares his consent to this.
    </p>
    <p>
      9.6.4 This license loses its validity if the User deletes the content from
      the Platform and its services.
    </p>
    <h2>10. Claims for defects</h2>
    <p>
      10.1 MK provides the user with access to the games and services in their
      respective existing version (clause 1.2). The user has no claim to
      maintain or create a certain condition/functionality of the game or
      services. The User is aware that the Games and Services - like any
      software - can never be completely error-free. The Games or Services shall
      therefore only be deemed defective if their playability or use is
      seriously and permanently impaired. Defects of the games, services or
      other performances or deliveries of MK detected by the user should be
      documented in a meaningful way and reported to MK in a suitable form (e.g.
      in text form as e-mail or via the contact form) under logging of displayed
      error messages for preservation of evidence. Before reporting a possible
      error, the user will consult the game or service manual and, if
      applicable, other assistance provided by MK for problem solving
      (especially lists of frequently asked questions, discussion forums for
      problems). The user shall support MK to the best of his ability in a
      possible correction of defects.
    </p>
    <p>
      10.2 The user has to notify MK of the defects detected and documented in
      the sense of item 10.1 within a period of 14 days after occurrence or
      knowledge of the defect.
    </p>
    <p>
      10.3 Basically excluded from warranty are such errors which are caused by
      external influences, responsible operating errors of the user, force
      majeure or changes or other manipulations not carried out by MK.
    </p>
    <p>10.4 Guarantees in the legal sense are not assumed by MK.</p>
    <h2>11. Limitation of liability</h2>
    <p>
      11.1 The user shall be directly liable to third parties in the event of an
      infringement of their rights for which he is responsible. The user commits
      himself to compensate MK for all damages resulting from the non-observance
      of the obligations resulting from these GTC. The user releases MK from all
      claims which other users or other third parties assert against MK because
      of violation of their rights by contents posted by the user or because of
      violation of other obligations. The user also takes over the costs of the
      necessary legal defense of MK including all court and lawyer fees. This
      does not apply if the user is not responsible for the violation of rights.
    </p>
    <p>
      11.2 As far as MK renders its services free of charge, MK is in no case
      liable for damages other than those caused by gross negligence or
      intention. However, this does not apply to the liability in case of injury
      of life, body and health or in case of acceptance of a guarantee by MK.
    </p>
    <p>
      11.3 As far as MK demands payment for its services, MK is liable without
      limitation in case of intent and gross negligence. In case of slight
      negligence MK is only liable in case of violation of essential contractual
      obligations or breach of a guarantee. Essential contractual obligations
      are such obligations which enable the proper execution of the contract in
      the first place and on whose fulfillment the user may rely. The
      aforementioned limitations of liability do not apply to the liability in
      case of injury of life, body and health or in case of acceptance of a
      guarantee by MK. The liability of MK according to the product liability
      law or within the scope of application of § 44a telecommunication law
      remains unaffected.
    </p>
    <p>
      11.4 In the event of a slightly negligent breach of material contractual
      obligations within the meaning of Clause 12.3. sentence 3, the obligation
      to pay compensation shall be limited to the damage foreseeable and typical
      for this type of contract at the time of conclusion of the contract.
    </p>
    <p>
      11.5 The above exclusions or limitations of liability also apply with
      regard to the liability of MK's employees, workers, co-workers,
      representatives and vicarious agents in particular in favour of the
      shareholders, co-workers, representatives, organs and their members
      regarding their personal liability.
    </p>
    <p>
      11.6 MK is liable for consulting only as far as the question has affected
      the content of the offer.
    </p>
    <p>
      11.7 MK expressly dissociates itself from the contents of all pages to
      which direct or indirect references (so-called "links") from the offer of
      MK exist. MK assumes no liability for these contents and pages. For the
      contents of these pages the providers of the respective pages are
      responsible themselves.
    </p>
    <h2>12. Profits</h2>
    <p>
      12.1 The user will be informed by MK after determination of the winning of
      a prize in a free raffle. In order to receive the material prize the user
      must provide MK with a deliverable address to which the prize shall be
      sent. In case of prizes which are harmful to minors the user is obliged to
      send MK a copy of the identity card or passport in order to carry out an
      age verification. After transmission of the aforementioned data MK will
      send the prize to the user within 14 days by mail or a shipping service
      provider. MK is authorized to credit users who do not provide an address
      in Germany, Austria or Spain with the "alternative chips" offered to the
      user.
    </p>
    <p>
      Employees of Schmidtgruppe and its partner companies as well as employees
      of affiliated companies and their relatives are excluded from
      participating in raffles.
    </p>
    <p>
      12.2 In the event that the winner does not provide MK with the required
      data or a delivery cannot take place or a delivery is returned due to a
      wrong address, any claim to the prize will expire after 4 weeks,
      regardless of whether it is a prize in kind which can be sent or not.
    </p>
    <h2>13. Online dispute resolution</h2>
    <p>
      13.1 The European Commission provides a platform for out-of-court online
      dispute resolution (OS platform), which can be accessed at
      <a href="http://www.ec.europa.eu/consumers/odr"
        >http://www.ec.europa.eu/consumers/odr</a
      >.
    </p>
    <p>
      13.2 The e-mail address of MK can be found in the imprint. We are neither
      obliged nor willing to participate in the dispute resolution procedure.
    </p>
    <h2>14. Final provisions</h2>
    <p>
      14.1 Users are not entitled to transfer their rights under this
      contractual relationship to third parties. An alternative is only valid if
      MK agrees to it in writing or in text form beforehand. MK is entitled to
      transfer the rights and obligations resulting from this contractual
      relationship to a third party. The user will be informed about the
      transfer at least four weeks in advance by announcement on the platform
      and / or e-mail notification. The user has the possibility to terminate
      the use of the games and / or services without notice. Any virtual
      currency available on the account at that time will be refunded to the
      User. Section 8.3.5 shall apply mutatis mutandis.
    </p>
    <p>
      14.2 Contracts concluded on the basis of these Terms and Conditions of use
      and claims in connection therewith shall be governed exclusively by the
      law of the Federal Republic of Germany, excluding the UN Convention on
      Contracts for the International Sale of Goods. If the user has concluded
      the contract as a consumer, the mandatory consumer protection provisions
      applicable in the state in which the user has his habitual residence shall
      also apply, provided that these provisions grant the user more extensive
      protection.
    </p>
    <p>
      14.3 Should individual provisions of these GTC be or become invalid, this
      shall not affect the validity of the remaining provisions.
    </p>
    <p>
      Coesfeld, the 31.10.2020 <br />
      Meine Krone GmbH <br />
      Druffels Weg 1 <br />48653 Coesfeld<br />
      E-mail: <a href="mailto:kontakt@sgservice.de">kontakt@sgservice.de</a
      ><br />
      Phone: +49 2541/94 06 0<br />
      Fax: +49 2541/94 06 20<br />
      Commercial register: HRB 17958 (Amstgericht Coesfeld) <br />
      VAT ID: 322521921<br />
      Managing directors: Arne Schmidt, Linus Menden<br />
    </p>
  </div>
</template>

<script>
export default {
  name: "TacTextEN",
  computed: {
    mainUrl() {
      return process.env.VUE_APP_HOST;
    },
  },
};
</script>
