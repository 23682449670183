<template>
  <div>
    <h1>
      Allgemeine Ge<wbr />schäfts<wbr />be<wbr />ding<wbr />ung<wbr />en für
      kleinekrone.de (nachstehend KK)
    </h1>
    <h2>1. Geltungsbereich</h2>
    <p>
      Dieses Angebot richtet sich ausschließlich an erwachsene Spieler ab 18
      Jahren. Unsere Social Casino-Spiele dienen der reinen Unterhaltung und
      haben keinen Einfluss auf Gewinne oder Erfolge bei Glücksspielen mit
      Geldeinsatz.
    </p>
    <p>
      Die „Meine Krone GmbH“ (nachstehend MK) bietet im Internet eine
      Unterhaltungsplattform unter
      <a :href="mainUrl">www.kleinekrone.de</a> an (nachstehend „Plattform“).
      Auf der Plattform werden verschiedene Spiele (Online-Spiele), sowie
      zusätzliche Unterhaltung und Dienste („Services“) angeboten, hierzu
      gehören z.B. das Erstellen eines Profils, das Beitreten eines
      Online-Teams, gegebenenfalls die Teilnahme an Chats, Sozialen Medien (im
      Besonderen Facebook, Instagram, Foren und Twitter), das Einbringen von
      Medieninhalten wie Fotos, Texten und Spielen, sowie der Erwerb von
      virtuellen Inhalten, Gegenständen oder sonstigen Dienstleistungen. Die
      Teilnehmer der Spiele von MK und die Nutzer der Plattform werden im
      Folgenden „Nutzer“ genannt.
    </p>
    <p>
      Sofern in diesen Allgemeinen Geschäftsbedingungen (im Folgenden „AGB“) von
      „Unterhaltung“ oder „Diensten“ die Rede ist, werden damit die von MK
      angebotene Unterhaltung und Dienste bezeichnet. Für die
      Geschäftsbeziehungen zwischen MK und den Nutzern geben wir unsere
      Allgemeinen Geschäftsbedingungen zur Kenntnis, die wir für die Erbringung
      aller auf der Plattform erbrachten Leistungen zugrunde legen, sowie auch
      die Datenschutzerklärung und das Impressum, welche einen integralen
      Bestandteil der AGB darstellen.
    </p>
    <p>
      Vertragspartner ist die Meine Krone GmbH. AGB des Nutzers werden
      ausdrücklich nicht Vertragsbestandteil, es sei denn, dass MK diesen
      ausdrücklich schriftlich zustimmt.
    </p>
    <p>
      Die nachfolgenden AGB regeln die Nutzung des Plattforms, der Spiele und
      Services. Die Nutzung kann in erster Linie per PC im Internet über
      Telefon, ISDN, DSL oder einen anderen gleichwertigen Anschluss erreicht
      werden. Zudem ist die Nutzung über verschiedene andere internetfähige
      Endgeräte möglich (via Appstore and Playstore), wobei hier der
      Funktionsumfang oder die Darstellung anders dargestellt oder eingeschränkt
      sein können.
    </p>
    <p>
      Gegenstand dieser AGB sind nicht etwaige Fragen, die sich im Zusammenhang
      mit der Überlassung der Anschlüsse sowie der Verbindung der Webseiten und
      der Online-Spiele und der Software Dritter (Browser, Zugangssoftware)
      stellen können, da diese Leistungen nicht von MK erbracht werden.
    </p>
    <h3>1.1 Nutzer</h3>
    <p>
      1.1.1 MK bietet die Spiele und Services ausschließlich Verbrauchern im
      Sinne des § 13 des Bürgerlichen Gesetzbuch (BGB) an. Die Nutzung der
      Spiele und Services zu Erwerbszwecken oder sonstigen gewerblichen Zwecken
      ist ausgeschlossen.
    </p>
    <p>
      1.1.2 Nutzungsberechtigt sind Personen, die zum Zeitpunkt der
      Nutzungsanmeldung das 18. Lebensjahr vollendet haben.
    </p>
    <p>
      1.1.3 Mit der Anmeldung zur Nutzung und/oder der Installation der Spiele
      oder Services versichert der Nutzer ausdrücklich seine Volljährigkeit und
      Geschäftsfähigkeit.
    </p>
    <p>
      1.1.4 Nutzer aus den Ländern Kuba, Iran, Nordkorea, Sudan und Syrien sind
      von der Nutzung der Spiele ausgeschlossen.
    </p>
    <h3>
      1.2 Vertragsgegenstand, Nutzung und Änderung der Spiele und Services
    </h3>
    <p>
      1.2.1 MK ermöglicht den Nutzern im Rahmen der vorhandenen technischen und
      betrieblichen Möglichkeiten die Teilnahme an den Spielen und Services.
    </p>
    <p>1.2.2 Die Teilnahme an den Spielen dient allein der Unterhaltung.</p>
    <p>
      1.2.3 Bei den Services hält MK auf der Plattform bestimmte Informationen
      zur Verfügung und ermöglicht den Nutzern u.a. Informationen ins Netz zu
      stellen, gegebenenfalls individuelle persönliche Profile anzulegen, die
      von Spielenutzern, Servicenutzern und Dritten eingesehen werden können.
      Ferner kann der Nutzer über die Plattform mit Dritten kommunizieren über
      einen Teamchat, Kommentare veröffentlichen, sowie Bewertungen über Spiele,
      andere Nutzer, Dritte oder Transaktionen abgeben.
    </p>
    <p>
      1.2.4 Bei den Spielen und Services handelt es sich um Dienstleistungen von
      MK, die in der Bereitstellung der Software zur Nutzung der Plattform für
      die Dauer des Vertrages besteht. Die Nutzung wird ermöglicht, indem MK die
      zur Teilnahme notwendige Applikation auf den jeweiligen URLs der Spiele
      bzw. Services online zur Verfügung stellt. MK behält sich vor, die Spiele
      auch – ggf. mit einem anderen Funktionsumfang – auf Datenträgern
      anzubieten, die käuflich erworben werden können. Der diesbezügliche
      Verkauf von Spielen unterliegt nicht diesen Geschäftsbedingungen.
    </p>
    <p>
      1.2.5 Die Teilnahme an den Spielen und die Nutzung der Services steht nur
      solchen Personen offen, die zuvor über den Weg einer Registrierung ein
      Kundenkonto (nachfolgend „Account“) erstellt haben. Die Registrierung
      erfolgt entweder online durch das Ausfüllen des Registrierungsformulars
      auf der Plattform oder über eine sonstige Webseite oder Landing Page mit
      einer entsprechenden Registrierungsfunktion, über die Anmeldefunktion
      eines Sozialen Netzwerks, wie z.B. durch Facebook Connect oder über die ID
      eines Appstores/Playstores. MK behält sich vor, die Freischaltung des
      Accounts von der Betätigung eines Bestätigungslinks abhängig zu machen,
      die dem Nutzer per E-Mail zugesandt wird. Ein Account kann entweder auf
      der Plattform oder auf der jeweiligen URL des Spiels erstellt werden. Ein
      auf der Plattform erstellter Account berechtigt den Nutzer unter den
      Voraussetzungen der Ziff. 1.1 zur Nutzung der Spiele und Services.
    </p>
    <p>
      1.2.6 Die Anmeldung, d.h. der Antrag auf Eröffnung eines Accounts, erfolgt
      durch elektronisches Ausfüllen eines Formulars und Eingabe verschiedener
      Informationen. Die Nutzung der Spiele und Services wird ab dem Zeitpunkt,
      zu dem MK für den Nutzer einen Account angelegt hat, ermöglicht.
    </p>
    <p>
      1.2.7 Ein Nutzer ist nur berechtigt einen Account auf der Plattform
      anzulegen. Für alle weiteren Accounts, behält sich MK das Recht vor diese
      als nicht zulässige Accounts zu sperren. Das Bespielen eines Accounts auf
      verschiedenen Endgeräten ist zulässig.
    </p>
    <p>
      Ebenso steht es MK frei, jeden User der Zahlungen storniert, falsche
      Zahlungsdaten eingibt, falsche Daten bei der Registrierung übermittelt,
      sich beleidigend, diffamierend, rassistisch, oder in anderer Art
      diskriminierend über oder gegenüber anderen Nutzern, MK Mitarbeitern, MK
      oder Dritten äußert, sei dieses im Chat, via E-Mail, in den Sozialen
      Medien oder auf andere Weise und Stelle, zeitweise oder komplett zu
      sperren und von den Spielen und Services auszuschließen. Dieses gilt
      ebenso für alle anderen Verstöße gegen die AGB und die Spielregeln bzw.
      Teilnahmebedingungen. Jegliche Ansprüche gegenüber MK auf Spielteilnahme,
      Gewinne, Preise, Accounts, Guthaben, geleistete Zahlungen und alle
      weiteren sich hieraus ergebenden Ansprüche erlöschen für den User bei dem
      Verstoß gegen die AGB.
    </p>
    <p>
      1.2.8 Nach Erstellung des Accounts kann der Nutzer am jeweiligen Spiel
      oder Service teilnehmen, indem er die Plattform oder die entsprechende URL
      aufruft und sich dann einloggt.
    </p>
    <p>
      1.2.9 Die Nutzung der Spiele und Services ist nur mittels eines
      Internetbrowsers oder spezieller, von MK zur Verfügung gestellter oder
      ausdrücklich zugelassener Tools zulässig („Verbot der Nutzung nicht
      autorisierter Skripte“). Dies bedeutet insbesondere: Die Verwendung von
      Programmen, die eine übermäßige Belastung des Servers erzeugen, ist
      unzulässig. Der Einsatz von Software zur systematischen oder automatischen
      Steuerung der Spiele oder einzelner Spielfunktionen (Bots, Makros), zur
      Reproduktion oder Auswertung der Spiele, Spielelementen oder der auf der
      Plattform eingestellten Inhalte ist unzulässig. MK Steht es frei Nutzern
      die dieses versuchen oder ausführen entweder zeitweise oder permanent zu
      sperren.
    </p>
    <p>
      1.2.10 Es ist verboten, Fehler in der Programmierung, Betrieb,
      Übermittlung oder anderer Ursache, für eigene Zwecke auszunutzen. Erkannte
      Bugs muss ein Nutzer nach Erkennen umgehend dem Support mitteilen.
      Vorteile, die sich für den Nutzer aus Fehlern und Bugs ergeben sind
      nichtig und begründen keinen Anspruch für den User. MK darf nach eigenem
      Ermessen entscheiden, wie mit diesen entstandenen Vorteilen für den Usern
      umzugehen ist.
    </p>
    <p>
      1.2.11 Der Nutzer hat keinen Anspruch auf Eröffnung eines Accounts oder
      der Veröffentlichung etwaiger Inhalte auf der Plattform.
    </p>
    <p>
      1.2.12 Die jeweils aktuellen technischen und sonstigen Spiel- und
      Teilnahmevoraussetzungen an den Services sind gegebenenfalls aus weiteren
      Informationen auf der Plattform oder den Webseiten der Spiele zu
      entnehmen.
    </p>
    <p>
      1.2.13 Die Spiele und Services werden laufend aktualisiert, angepasst,
      erweitert und verändert. Der Nutzer erhält dementsprechend nur ein
      Nutzungsrecht an dem jeweiligen Spiel und den Services in der jeweils
      aktuellen Fassung.
    </p>
    <p>
      1.2.14 Die Nutzung der Spiele in ihrer Grundversion ist kostenlos. Der
      Nutzer kann aber verschiedene Dienste und virtuellen Güter erwerben (siehe
      hierzu Punkt 7). Sofern nicht in den Beschreibungen der jeweiligen
      Services vereinbart, ist die Nutzung der Services entgeltfrei.
    </p>
    <p>
      1.2.15 Ein Anspruch des Nutzers auf Aufrechterhaltung der Spiele oder
      Services, in der bei Vertragsschluss bestehenden Version, besteht nicht.
      MK behält sich vor, den Betrieb eines Spiels oder Services jederzeit, ohne
      Angabe von Gründen einzustellen. Ansprüche des Nutzers sind
      ausgeschlossen, sofern nicht ausdrücklich anders in diesen AGB vorgesehen.
    </p>
    <h2>2. Vertragsangebot und Vertragsschluss</h2>
    <p>
      2.1 Mit Ausfüllen des Registrierungsformulars gibt der Nutzer ein
      verbindliches Angebot zum Abschluss eines Spiele und
      Servicenutzungsvertrags (auch „Nutzerantrag“) ab. Dazu sind alle als
      notwendig gekennzeichneten Datenfelder des Registrierungsformulars
      vollständig und richtig auszufüllen.
    </p>
    <p>
      2.2 Der Vertrag zwischen MK und dem Nutzer kommt mit Annahme des
      Nutzerantrags durch MK zustande. Die Annahme kann ausdrücklich oder durch
      die erste Erfüllungshandlung, insbesondere durch die Bereitstellung des
      Spiels oder Services, erfolgen.
    </p>
    <p>
      2.3 Soweit für die Nutzung eines Spiels oder Services die Erstellung eines
      Accounts notwendig ist, wird der Zugang des Nutzerantrags durch MK
      unverzüglich auf elektronischem Weg an die vom Nutzer angegebene
      E-Mail-Adresse bestätigt. Die Zugangsbestätigung stellt keine verbindliche
      Annahme des Nutzerantrags dar. Die Zugangsbestätigung kann aber mit der
      Annahmeerklärung verbunden werden.
    </p>
    <h2>3. Widerrufsbelehrung</h2>
    <p class="bold">
      Wenn der Nutzer ein Verbraucher mit gewöhnlichem Aufenthaltsort oder
      Wohnsitz in der Europäischen Union ist, steht ihm ein gesetzliches
      Widerrufsrecht nach Maßgabe folgender Widerrufsbelehrung zu:
    </p>
    <h3>3.1 Widerrufsbelehrung</h3>
    <p class="text-uppercase bold">Widerrufsrecht</p>
    <p class="bold">
      Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen
      Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag
      des Vertragsabschlusses. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns,
      mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter
      Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu
      widerrufen, informieren. Sie können dafür das beigefügte
      Muster-Widerrufsformular (siehe Ziff. 3.3.) verwenden, das jedoch nicht
      vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie
      die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der
      Widerrufsfrist absenden.
    </p>
    <p class="text-uppercase bold">Folgen des Widerrufs</p>
    <p class="bold">
      Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die
      wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit
      Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine
      andere Art der Lieferung als die von uns angebotene, günstigste
      Standardlieferung gewählt haben), unverzüglich und spätestens binnen
      vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren
      Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung
      verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen
      Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich
      etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser
      Rückzahlung Entgelte berechnet.
    </p>
    <h3>3.2 Vorzeitiges Erlöschen des Widerrufsrechts</h3>
    <p class="bold">
      Ihr Widerrufsrecht erlischt bei einem Vertrag über die Lieferung von nicht
      auf einem körperlichen Datenträger befindlichen digitalen Inhalten
      vorzeitig, wenn Sie ausdrücklich zugestimmt haben, dass wir mit der
      Ausführung des Vertrages vor Ablauf der Widerrufsfrist beginnen und Sie
      Ihre Kenntnis davon bestätigt haben, dass Sie durch Ihre Zustimmung mit
      dem Beginn der Ausführung des Vertrages Ihr Widerrufsrecht verlieren. Das
      Widerrufrecht erlischt wenn die virtuellen Güter genutzt oder verbraucht
      werden, z.B. durch den Einsatz in einem Spiel, der Ausgang des Spiels ist
      hierbei unerheblich. Einmal eingesetzte Güter gelten als verbraucht.
      Hierbei gilt, das käuflich erworbene Güter immer zuerst genutzt werden.
    </p>
    <p class="text-uppercase bold">Ende der Widerrufsbelehrung</p>
    <h3>3.3 Muster-Widerrufsformular</h3>
    <p>
      Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses
      Formular aus und senden Sie es zurück.
    </p>
    <p>
      An:<br />
      Meine Krone GmbH <br />
      Druffels Weg 1 <br />48653 Coesfeld<br />
      E-Mail: <a href="mailto:kontakt@sgservice.de">kontakt@sgservice.de</a
      ><br />
    </p>
    <p>
      Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen
      Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der folgenden
      Dienstleistung (*)
    </p>
    <ul>
      <li>Bestellt am (*)/erhalten am (*)</li>
      <li>Name des/der Verbraucher(s)</li>
      <li>Anschrift des/der Verbraucher(s)</li>
      <li>
        Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
      </li>
      <li>Datum</li>
    </ul>
    <p>(*) Unzutreffendes streichen.</p>
    <!-- <p>
      Das Musterwiderrufsformular kann zudem hier abgerufen werden.
    </p> -->
    <h2>4. Erreichbarkeit</h2>
    <p>
      MK gewährleistet eine Erreichbarkeit der Spiele und Services von neunzig
      Prozent im Jahresmittel. Hiervon ausgenommen sind Zeiten, in denen die
      Server der Plattforms, oder der einzelnen Spiele, auf Grund von
      technischen oder sonstigen Problemen, die nicht im Einflussbereich der MK
      liegen (höhere Gewalt, Verschulden Dritter usw.), über das Internet nicht
      zu erreichen sind, sowie Zeiten, in denen routinemäßige Wartungsarbeiten
      durchgeführt werden. Die Haftung der MK für eine Nichterreichbarkeit der
      Spiele und Services bei Vorsatz und grober Fahrlässigkeit bleibt
      unberührt. MK kann den Zugang zu den Leistungen beschränken, sofern die
      Sicherheit des Netzbetriebes, die Aufrechterhaltung der Netzintegrität,
      insbesondere die Vermeidung schwerwiegender Störungen des Netzes, der
      Software oder gespeicherter Daten dies erfordern.
    </p>
    <h2>
      5. Zugang und Kenntnisnahme der AGB, Änderungen und weitere
      Benachrichtigungen, Kontaktaufnahme durch den Nutzer
    </h2>
    <p>
      5.1 Diese AGB gelten für jedwedes Einloggen auf der Plattform,
      insbesondere für jede Teilnahme an den Spielen oder Nutzung der Services.
      Die AGB können vor Absendung des Nutzerantrages vom Nutzer ausgedruckt
      oder auf einem dauerhaften Datenträger gespeichert werden.
    </p>
    <p>
      5.2 MK behält sich das Recht vor, die AGB mit Wirkung für die Zukunft
      jederzeit zu ändern oder zu ergänzen, sofern dies notwendig erscheint und
      der Nutzer hierdurch nicht wider Treu und Glauben benachteiligt wird. Eine
      Änderung kann insbesondere notwendig sein, (a) um Anpassungen an eine
      Änderung der Rechtslage vorzunehmen, (b) bei Änderungen der
      höchstrichterlichen Rechtsprechung, (c) wegen technischer Notwendigkeit,
      (d) zur Aufrechterhaltung des Betriebs, (e) bei Veränderung der
      Marktgegebenheiten, (f) zum Vorteil des Nutzers.
    </p>
    <p>
      5.3 Eine Änderung oder Ergänzung der AGB wird mindestens vier Wochen vor
      Inkrafttreten in geeigneter Art und Weise in Textform bekannt gegeben. In
      der Regel erfolgt der Hinweis auf die Anpassung der AGB durch Bekanntgabe
      per E-Mail oder auf der Plattform bzw. der Webseite der Spiele oder in den
      Spielen, in jedem Fall jedoch beim nächsten Einloggen des Nutzers durch
      eine hervorgehobene Ankündigung.
    </p>
    <p>
      5.4 Der Nutzer hat das Recht, einer Änderung oder Ergänzung dieser AGB
      innerhalb vier Wochen nach Veröffentlichung und Möglichkeit der
      Kenntnisnahme gegenüber MK zu widersprechen. Im Falle eines rechtzeitigen
      Widerspruchs sind beide Parteien berechtigt, den Vertrag nach den
      Kündigungsregelungen dieses Vertrages außerordentlich zu kündigen.
      Sonstige Kündigungsrechte bleiben hiervon unberührt. Etwaige im Voraus
      über den Beendigungszeitraum geleistete Leistungsentgelte werden dem
      Nutzer in diesem Fall anteilig zurückerstattet; weitere Ansprüche sind
      ausgeschlossen. Widerspricht der Nutzer nicht innerhalb der vierwöchigen
      Widerspruchsfrist oder nutzt er die Leistungen danach weiterhin, gilt die
      Änderung oder Ergänzung als angenommen und wird Vertragsbestandteil. Die
      Bestimmungen von Ziff. 5.1. gelten sinngemäß.
    </p>
    <p>
      5.5 MK wird den Nutzer in der Unterrichtung über die Änderungen oder
      Ergänzungen der AGB auf die Möglichkeit des Widerspruchs und der
      Kündigung, die Frist und die Rechtsfolgen, insbesondere hinsichtlich eines
      unterbliebenen Widerspruchs, besonders hinweisen.
    </p>
    <p>
      5.6 MK wird, soweit nichts Anderes durch diese AGB oder anderweitige
      Vereinbarung mit dem Nutzer bestimmt wird, in der Regel mit dem Nutzer per
      E-Mail kommunizieren. Der Nutzer stellt sicher, dass ihn E-Mails, die von
      MK an die von ihm bei der Registrierung angegebene oder später der MK
      mitgeteilte E-Mail-Adresse versandt werden, erreichen. Er wird dafür u.a.
      durch entsprechende Einstellungen des Spam-Filters sorgen und diese
      Adresse regelmäßig überprüfen. Im Übrigen bleibt es MK vorbehalten, eine
      andere geeignete Form der Korrespondenz zu wählen.
    </p>
    <p>
      5.7 Der Nutzer soll bei jeder Kontaktaufnahme mit MK angeben, auf welches
      der Spiele oder Services und auf welchen Spielaccount bzw. Serviceaccount
      sich sein Anliegen bezieht.
    </p>
    <h2>6. Spielanleitungen, Spielregeln, Teilnahmebedingungen</h2>
    <p>
      6.1 Ergänzend zu diesen AGB gelten die für das jeweilige Spiel oder die
      Inanspruchnahme einer Dienstleistung unter Umständen gesonderte
      Spielregeln bzw. Teilnahmebedingungen. Sofern nicht ausdrücklich anders
      bestimmt gehen im Fall von Widersprüchen zwischen diesen AGB und den
      Spielregeln bzw. Teilnahmebedingungen die Bestimmungen dieser AGB zur
      Regelung des den Widerspruch hervorrufenden Sachverhalts grundsätzlich
      vor. Für einzelne Spiele, besondere Varianten und/oder Bestandteile der
      Spiele und einzelne Dienstleistungsangebote auf der Plattform gelten
      darüber hinaus gegebenenfalls besondere Nutzungsbedingungen. Hierauf wird
      der Nutzer vor Inanspruchnahme des jeweiligen Angebots in geeigneter Form
      hingewiesen.
    </p>
    <p>
      6.2 Der Nutzer ist sich bewusst, dass er gegebenenfalls mit anderen
      Nutzern zusammen spielt bzw. auf der Plattform mit verschiedenen
      Servicenutzern kommuniziert. Um ein gedeihliches Zusammenspiel zu
      ermöglichen, ist die Einhaltung von Regeln notwendig.
    </p>
    <p>
      6.3 Der Nutzer wird mit Blick auf Ziffer 6.2. alles unterlassen, was den
      Betrieb der Spiele und Services und des gedeihlichen Zusammenspiels stört.
    </p>
    <h2>7. Kostenfreies Spielen, Zahlungen, Zahlungsbedingungen, Verzug</h2>
    <h3>7.1 Online-Spiele</h3>
    <h4>7.1.1 Grundversion</h4>
    <p>
      MK stellt den Nutzern die Spiele grundsätzlich ab Erstellung eines
      Accounts zur Verfügung (siehe oben 1.2). In diesem Fall steht dem Nutzer
      zunächst eine Grundversion zur Verfügung. Die Erstellung des Accounts und
      die Nutzung der Grundversion sind kostenlos. Die Grundversion ist
      (unbeschadet des Rechts der MK zur Einstellung der Spiele) zeitlich nicht
      limitiert und voll spielbar. In der Grundversion stehen dem Nutzer aber
      gegebenenfalls nicht alle Features zur Verfügung. Einige dieser Features
      können durch den User mit Laude des Spiels freigeschaltet werden.
    </p>
    <h4>7.1.2 Features</h4>
    <p>
      Der Nutzer hat die Möglichkeit, gegen Zahlung eines Entgelts, virtuelle
      Güter und gegebenenfalls Features zu erhalten, sowie Zeitvorteile zu
      erwerben, die in der Grundversion nicht, oder nur eingeschränkt zur
      Verfügung stehen. Welche virtuellen Güter und Features zu welchem Tarif
      angeboten werden, welche Funktionen diese haben und welche Voraussetzungen
      sie erfordern, ist der Information des Spiels zu entnehmen. Dabei kann es
      sich je nach dem konkreten Feature und Tarif um Einmalzahlungen handeln,
      um die Aufladung eines in dem jeweiligen Spiel gemäß dessen Regeln für
      bestimmte Funktionen nutzbaren Guthabens (Gold oder Kronen) oder um
      Zahlungen, die für einen bestimmten Zeitabschnitt (zum Beispiel Tag,
      Woche, Monat, Vierteljahr, Halbjahr, Jahr) zu leisten sind.
    </p>
    <p>
      Alle Preise verstehen sich als Bruttopreise, also inklusive etwaig
      anfallender Steuern. Die Spiele werden ständig weiterentwickelt. MK behält
      sich daher vor, jederzeit neue Features anzubieten. Im Zuge der Anpassung
      und Weiterentwicklung der Spiele behält sich MK ebenfalls vor, einzelne
      Features nicht mehr anzubieten oder auch in der Grundversion (vgl. oben
      7.1.1) anzubieten. Ansprüche des Nutzers auf Erstattung der dafür
      geleisteten Entgelte sind ausgeschlossen.
    </p>
    <p>
      Im Falle der dauerhaften Einstellung eines MK Spiels gilt Abschnitt
      1.2.15.
    </p>
    <h4>7.1.3 Abonnement / Automatische Verlängerung</h4>
    <p>
      Sofern Zahlungen für Premium-Features für einen bestimmten Zeitabschnitt
      zu leisten sind, schließt der Nutzer ein Abonnement ab, das sich
      automatisch verlängert, sofern es der Nutzer nicht rechtzeitig gemäß Ziff.
      8.2 zum Ablauf des jeweiligen Zeitabschnittes kündigt. Die Laufzeiten des
      Abonnements sind den für das jeweilige Spiel anwendbaren Spielregeln bzw.
      Teilnahmebedingungen zu entnehmen.
    </p>
    <h4>7.1.4 Zahlungsbedingungen, Fälligkeit</h4>
    <p>
      MK ist berechtigt, für die Nutzung der Features oder bestimmter virtueller
      Güter (siehe oben 7.1.2) Leistungsentgelte im Voraus zu verlangen. Die
      Leistungsentgelte werden mit Vertragsabschluss fällig und werden von dem
      vom Nutzer angegebenen Bankkonto bzw. der Kreditkarte abgebucht, sofern
      der Nutzer nicht eine andere Zahlungsweise (beispielsweise Appstore,
      Paypal) wählt. Der einzuziehende Betrag wird auf der
      Kreditkartenabrechnung bzw. auf dem Kontoauszug als www.kleinekrone.de “
      oder „Meine Krone“, „Meine Krone GmbH“ oder „Adyen Meine Krone“
      ausgewiesen. Bei der Nutzung alternativer Bezahlmethoden kann die
      Bezeichung abweichen.
    </p>
    <h4>7.1.5 Anpassungen der Entgelte</h4>
    <p>
      MK ist jederzeit berechtigt, die Preise dauerhaft oder für eine begrenzte
      Zeit zu senken oder zu erhöhen, sowie dauerhaft oder für eine begrenzte
      Zeit neue Produkte, Leistungen oder Abrechnungsmodalitäten anzubieten. Das
      Vertragsverhältnis wird dann zu den geänderten Konditionen/Preisen
      unbeschadet der Rechte des Nutzers gemäß Ziff. 8.2 und Ziff. 8.3
      fortgesetzt.
    </p>
    <h4>7.1.6 Anpassungen der Entgelte bei Abonnements</h4>
    <p>
      MK ist berechtigt, die Entgelte für die vertraglich vereinbarten
      Leistungen bei Abonnements nach folgendem Maßgabe anzupassen: Eine
      Preisänderung wird dem Nutzer mindestens vier Wochen vor ihrem
      Inkrafttreten durch schriftliche Erklärung oder per E-Mail angekündigt.
      Der Nutzer hat ab Datum der Ankündigungserklärung ein vierwöchiges
      außerordentliches Recht zur Kündigung des Vertrages zum Zeitpunkt der
      Preisänderung. Auf dieses Recht weist MK in der Ankündigungserklärung hin.
      Übt der Nutzer dieses Recht nicht aus, gilt die Preisänderung als
      genehmigt. Preissenkungen gelten grundsätzlich ab dem angekündigten Termin
      für die jeweils nächste Abrechnungsperiode.
    </p>
    <h3>7.2 Verzug</h3>
    <p>
      Im Verzugsfall ist MK berechtigt, Zinsen in der jeweils gesetzlich
      vorgesehenen Höhe zu verlangen. MK ist im Verzugsfall weiter berechtigt,
      die Leistungen einzustellen, bzw. die Accounts der Nutzer sofort zu
      sperren. Für den Zeitraum der Sperrung fällt für etwaige geschlossene
      Abonnements kein Leistungsentgelt an.
    </p>
    <h3>7.3 Rückbelastungen, Stornoentgelt</h3>
    <p>
      Sollten MK durch ein Verschulden des Nutzers oder durch vom Nutzer zu
      vertretende Umstände (hierzu gehört beispielsweise auch eine unzureichende
      Kontodeckung) Stornogebühren im Falle von stornierten Transaktionen
      entstehen, so trägt der Nutzer die der MK aufgrund dessen tatsächlich
      entstandenen bzw. weiterbelasteten Kosten. MK ist berechtigt, diese Kosten
      zusammen mit dem ursprünglichen Entgelt vom Nutzer einzufordern. Dem
      Nutzer steht es in allen Fällen frei nachzuweisen, dass ein Schaden nicht
      entstanden oder wesentlich niedriger ist.
    </p>
    <h3>7.4 Keine Gewinngarantie</h3>
    <p>
      MK garantiert den Nutzern keine Gewinne. Insbesondere haben die Nutzer
      keinen Anspruch auf eine Auszahlung eines Gewinns oder Preises. Die Nutzer
      spielen mit virtuellen Gütern und virtuellen Währungen für
      Unterhaltungszwecke. Ein Anspruch auf Auszahlung eines Gewinns im Rahmen
      eines Gewinnspiels kann nur bestehen, wenn ein solcher Gewinn von MK
      ausdrücklich im Rahmen eines Gewinnspiels explizit ausgelobt wurde. Ein
      Anspruch besteht auch dann nicht, wenn MK feststellt, dass der mögliche
      Gewinnanspruch aufgrund von Manipulationen – technischer oder rechtlicher
      Art oder aufgrund jeder strafrechtlich relevanten Manipulation im
      Allgemeinen – entstanden sein könnte. Mit der Teilnahme an den Spielen der
      MK erklärt sich der Nutzer damit einverstanden, dass MK hinsichtlich der
      Rechtmäßigkeit eines Gewinnanspruchs jederzeit eine umfangreiche
      Untersuchung verlangen kann und in der Zwischenzeit ohne gesondertes
      Einverständnis des Nutzers die Auszahlung des Gewinns verweigern kann. Der
      Gewinnanspruch wird auch dadurch verwirkt, wenn der Nutzer sich entgegen
      den Spielregeln verhält.
    </p>
    <h3>7.5 Keine Aufrechnung, Zurückbehaltungsrechte, Abtretungsverbot</h3>
    <p>
      Gegen Forderungen der MK kann der Nutzer nur mit unbestrittenen oder
      rechtskräftig festgestellten Gegenansprüchen aufrechnen. Der Nutzer kann
      ein Zurückbehaltungsrecht nur ausüben, wenn sein Gegenanspruch auf
      demselben Vertragsverhältnis beruht. Die Abtretung von Ansprüchen des
      Nutzers gegen MK an Dritte ist ausgeschlossen.
    </p>
    <h3>7.6 Service</h3>
    <p>
      Sofern nicht anders in der Servicebeschreibung vereinbart, sind Services
      entgeltfrei. Im Übrigen gelten die Ziffern 7.1 bis 7.5 entsprechend für
      kostenpflichtige Services.
    </p>
    <h2>8. Laufzeit, Kündigung</h2>
    <h3>8.1 Laufzeit</h3>
    <p>
      Die Verträge zwischen dem Nutzer und MK werden auf unbestimmte Zeit
      geschlossen, sofern in dem konkreten Angebot der MK nichts anderes
      bestimmt ist.
    </p>
    <h3>8.2 Ordentliche Kündigung</h3>
    <p>
      Jede Partei ist befugt die Vertragsverhältnisse unter Einhaltung einer
      Kündigungsfrist ohne Angabe von Gründen wie folgt zu kündigen:
    </p>
    <p>
      8.2.1 Ist für einen Vertrag eine befristete Laufzeit nicht vereinbart
      worden, steht beiden Parteien ein jederzeitiges ordentliches
      Kündigungsrecht mit sofortiger Wirkung zu.
    </p>
    <p>
      8.2.2 Ist für einen Vertrag (beispielsweise: einen Spiel oder
      Servicenutzungsvertrag bzw. einen Vertrag) über die
      Nutzung/Zurverfügungstellung von PremiumFeatures (siehe hierzu auch Ziff.
      7.1.2 und 7.1.3) eine bestimmte Laufzeit vereinbart worden, so verlängert
      dieser Vertrag sich nach Ablauf jeweils automatisch um die gleiche
      Laufzeit, bis der Nutzer das Abonnement zum Ende der jeweiligen Laufzeit
      kündigt.
    </p>
    <h3>8.3 Kündigung aus wichtigem Grund</h3>
    <p>
      8.3.1 Das Recht der Parteien zur jederzeitigen Kündigung aus wichtigem
      Grund bleibt von den vorstehenden Regelungen unberührt.
    </p>
    <p>
      8.3.2 Hat MK die außerordentliche Kündigung zu vertreten, werden dem
      Nutzer die gegebenenfalls von ihm im Voraus über den Beendigungszeitraum
      hinaus geleisteten Entgelte (insbesondere für Premium-Features) anteilig
      zurückerstattet. Weitere Ansprüche des Nutzers sind ausgeschlossen, sofern
      nicht anders in diesen AGB vereinbart.
    </p>
    <p>
      MK ist insbesondere, aber nicht ausschließlich, zur Kündigung aus
      wichtigem Grund berechtigt, wenn:
    </p>
    <ol>
      <li>
        der Nutzer schuldhaft gegen Gesetze, diese AGB, die Spielregeln und/oder
        Teilnahmebedingungen verstößt und sich trotz Abmahnung wiederholt in
        gleicher oder ähnlicher Art und Weise nicht regelgerecht verhält;
      </li>
      <li>
        der Nutzer mit der Zahlung der Entgelte mit einem Betrag in Höhe von
        mindestens EUR 10,00 in Verzug gerät und trotz zweifacher Mahnung nicht
        zahlt;
      </li>
      <li>
        Dritte (z.B. Betreiber von Sozialen Netzwerken), über deren
        Anmeldefunktion der Nutzer Zugang zu seinem Account bei dem Anbieter
        erlangt, den Anbieter zur Löschung von Nutzerdaten und/oder
        vergleichbaren Maßnahmen auffordern; soweit eine alternative
        Zugangsgewährung für den Anbieter unzumutbar wäre;
      </li>
      <li>
        Dritte (z.B. Betreiber von Sozialen Netzwerken), über deren
        Anmeldefunktion der Nutzer Zugang zu seinem Account bei dem Anbieter
        erlangt, den Zugang des Anbieters auf Daten einschränken, soweit eine
        alternative Zugangsgewährung für den Anbieter unzumutbar wäre.
      </li>
    </ol>
    <p>
      8.3.3 Bei schwerwiegenden Verstößen ist eine sofortige Kündigung zulässig,
      ohne dass es einer vorherigen Abmahnung bedarf. Ein schwerwiegender
      Verstoß ist ein Verstoß, bei dem MK ein Festhalten am Vertrag nicht
      zugemutet werden kann. Dies ist in der Regel der Fall,
    </p>
    <ol>
      <li>wenn der Nutzer gegen Strafgesetze verstößt;</li>
      <li>
        wenn der Nutzer den Account, ein Spiel oder Services in unzulässiger
        Weise nutzt;
      </li>
      <li>
        wenn der Nutzer bei der Registrierung oder bei der Bezahlung von
        kostenpflichtigen Leistungen falsche Daten angibt.
      </li>
    </ol>
    <p>
      8.3.4 Im Falle einer durch MK berechtigt ausgesprochenen Kündigung aus
      wichtigem Grund ist MK berechtigt, einen Betrag in Höhe von 50% der Summe
      aller Entgelte, die der Nutzer bei zeitgleicher fristgerechter Kündigung
      während der Vertragslaufzeit (für vom Anbieter noch nicht erbrachte
      Leistungen, insbesondere für bereits bestellte virtuelle Währungen oder
      Premium-Features) hätte entrichten müssen, zu verlangen. Das Recht des
      Nutzers nachzuweisen, dass kein oder ein wesentlich geringerer Schaden
      entstanden ist, bleibt unberührt. Soweit der Nutzer die von MK zu
      erbringende Leistung bereits erhalten hat, besteht kein Anspruch auf
      Rückerstattung. Insbesondere erfolgt keine Rückerstattung für virtuelle
      Währungen oder Features, die der Nutzer bei MK bestellt und bereits
      erhalten oder genutzt hat.
    </p>
    <p>
      8.3.5 Hat MK die außerordentliche Kündigung zu vertreten und hat der
      Nutzer noch virtuelle Währung für ein Spiel oder Services auf seinem
      Account, wird MK dem Nutzer die virtuelle Währung auf ein vom Nutzer
      gewünschtes Spiel oder Services, welche MK anbietet, gutschreiben. Eine
      Rückzahlung in bar ist ausgeschlossen, es sei denn die Gutschrift ist dem
      Nutzer ausnahmsweise unter Berücksichtigung der beiderseitigen Interessen
      nicht zuzumuten, z. B. weil kein gleichwertiges oder ähnliches Spiel bzw.
      Services angeboten werden. In diesem Fall wird MK dem Nutzer den Wert von
      eventuell noch auf seinem Account befindlicher virtueller Währung in der
      dann noch vorhandenen Höhe des tatsächlich vom Nutzer eingezahlten
      Betrages erstatten. Weitere Ansprüche des Nutzers sind ausgeschlossen,
      sofern diese AGB nichts anderes bestimmen.
    </p>
    <p>
      8.3.6 MK steht ein Sonderkündigungsrecht bezüglich der Registrierung des
      Nutzers für Spiele bzw. Services für den Fall zu, dass MK die Berechtigung
      zum Betrieb der Spiele oder Services verliert, z. B. aus regulatorischen
      Gründen oder wegen der Beendigung des betreffenden Lizenzvertrages
      zwischen MK und dem jeweiligen Lizenzgeber. MK kann in diesem Fall alle
      auf den Betrieb und die Nutzung des Spiels bzw. Services bezogenen
      Verträge (z.B. Nutzungsvertrag, Vertrag über die Zurverfügungstellung und
      Nutzung von virtueller Währung oder Premium-Features) zum
      Einstellungszeitpunkt des Spielbetriebs außerordentlich kündigen. Es
      gelten dann die Regelungen nach Ziff. 8.3.5 Sonstige Kündigungsrechte
      bleiben unberührt.
    </p>
    <p>
      8.3.7 Unentgeltlich gewährte virtuelle Währung oder Premium Features sind
      grundsätzlich von einer Erstattung ausgeschlossen.
    </p>
    <h3>8.4 Kündigung in Textform</h3>
    <p>
      Jede Kündigung bedarf zu ihrer Wirksamkeit der Textform (z.B. E-Mail).
    </p>
    <h2>
      9. Pflichten und andere Obliegenheiten des Nutzers, sowie Haftung des
      Nutzers für von ihm eingestellte Informationen, Urheberrechte
    </h2>
    <h3>9.1 Pflichten</h3>
    <p>
      Hauptleistungspflicht des Nutzers ist die Zahlung eines vorgesehenen
      Entgelts, sofern er nicht die kostenlose Basis-Version des Spiels bzw. der
      Services nutzt (siehe oben Ziff. 7). Hauptleistungspflicht des Nutzers ist
      darüber hinaus die Pflicht, sich an die geltenden Spielregeln zu halten,
      sowie die Pflicht zur richtigen und vollständigen Angabe von Daten, die MK
      bei Vertragsschluss oder im Fortgang der vertraglichen Beziehung vom
      Nutzer abfragt. Der Nutzer versichert daher, dass die von ihm im Rahmen
      des Vertragsangebots oder des Vertragsschlusses oder im Fortgang der
      vertraglichen Beziehungen gemachten Angaben über seine Person und sonstige
      vertragsrelevante Umstände (insbesondere Zahlungsdaten) vollständig und
      richtig sind. Der Nutzer verpflichtet sich, MK jeweils unverzüglich über
      Änderungen der Daten zu unterrichten; auf entsprechende Anfrage der MK hat
      der Nutzer die Daten zu bestätigen. Der Nutzer ist verpflichtet, sich an
      die Spielregeln zu halten. Bei einem Verstoß ist MK berechtigt, die
      vertraglichen Leistungen und Lieferungen sofort und ohne Einräumung einer
      Frist zu sperren und den Vertrag zu kündigen.
    </p>
    <h3>9.2 Installation von Software</h3>
    <p>
      MK haftet nicht für Schäden oder Datenverluste, die durch die Installation
      von Software, die nicht von MK herrührt, auf dem Rechner des Nutzers
      entstehen können.
    </p>
    <h3>9.3 Weitere Obliegenheiten des Nutzers</h3>
    <p>
      9.3.1 MK stellt die Spiele bzw. Services online zur Nutzung mittels eines
      Web-Browsers zur Verfügung. Die lokal auf dem Rechner des Nutzers
      benötigte Software (insbesondere Betriebssystem und Web-Browser, ggf. auch
      Plug-Ins wie Flash oder Java) werden von MK weder zur Verfügung gestellt
      noch installiert. MK leistet für diese auch keine Unterstützung. Es
      obliegt dem Nutzer, den Rechner in einem Zustand zu halten, der die
      Nutzung der Spiele der MK ermöglicht. MK leistet dementsprechend auch
      keine technische Hilfestellung bei der Installation der lokal benötigten
      Software.
    </p>
    <p>
      9.3.2 Der Nutzer verpflichtet sich, die von MK zum Zwecke des Zugangs zu
      deren Spielen bzw. Services erhaltene Zugangsdaten (Login Daten,
      Passwörter etc.) streng geheim zu halten. Der Nutzer wird MK unverzüglich
      informieren, sobald er davon Kenntnis erlangt oder vermutet, dass
      unbefugten Dritten die Zugangsdaten bekannt sind. Aus
      Beweissicherungsgründen wird dem Nutzer empfohlen, dies schriftlich (z.B.
      per E-Mail) zu tun. Benutzt ein Dritter einen Account, nachdem er an die
      Zugangsdaten gelangt ist, weil der Nutzer diese nicht hinreichend vor
      fremdem Zugriff gesichert hat, muss sich der Nutzer wegen der von ihm
      geschaffenen Gefahr einer Unklarheit darüber, wer unter dem betreffenden
      Account gehandelt hat und im Falle einer Vertrags- oder anderen
      Rechtsverletzung in Anspruch genommen werden kann, so behandeln lassen,
      als ob er selbst gehandelt hätte. MK ist berechtigt, jeden Zugang mit den
      Daten des Nutzers als Zugang des Nutzers selbst zu werten. MK weist darauf
      hin, dass Passwörter aus Sicherheitsgründen regelmäßig geändert werden
      sollten. Der Nutzer ist für die Verwendung seines Accounts allein
      verantwortlich.
    </p>
    <p>
      9.3.3 Im Falle eines begründeten Verdachts, dass Zugangsdaten unbefugten
      Dritten bekannt wurden, ist MK aus Sicherheitsgründen berechtigt, aber
      nicht verpflichtet, nach freiem Ermessen die Zugangsdaten ohne vorherige
      Ankündigung selbständig zu ändern bzw. die Nutzung des Accounts zu
      sperren. Sie wird den berechtigten Nutzer hierüber unverzüglich
      informieren und ihm auf Anforderung innerhalb angemessener Frist die neuen
      Zugangsdaten mitteilen. Der Nutzer hat keinen Anspruch darauf, dass die
      ursprünglichen Zugangsdaten wiederhergestellt werden.
    </p>
    <p>
      9.3.4 Der Nutzer ist nicht berechtigt, seinen Account an Dritte zu
      verkaufen oder sonst weiterzugeben oder zu verleihen. Ebenso ist es dem
      Nutzer untersagt jegliche Leistungen, Features und Virtuelle Güter an
      Dritte zu verkaufen, abzutreten oder gewerblich zu nutzen.
    </p>
    <p>
      9.3.5 MK sichert ihre Systeme gegen Virenbefall. Dennoch kann ein
      Virenbefall nie vollständig ausgeschlossen werden. Zudem kann es
      vorkommen, dass unberechtigte Dritte E-Mails unter Verwendung des Namens
      der MK ohne deren Einverständnis versenden, die beispielsweise Viren oder
      sog. Spyware enthalten oder zu Webinhalten linken, die Viren oder Spyware
      enthalten. Darauf hat MK keinen Einfluss. Der Nutzer wird daher alle
      eingehenden E-Mails, die von MK oder angeblich in deren Namen versandt
      sind, auf Virenbefall prüfen. Dieses gilt auch für E-Mails von anderen
      Nutzern der Spiele oder Services.
    </p>
    <p>
      9.3.6 Der Nutzer wird den Anordnungen der MK und ihren Mitarbeitern, sowie
      ihrer Erfüllungs- und Verrichtungsgehilfen Folge leisten. Dieses gilt
      insbesondere auch für die Anweisungen der Administratoren und Moderatoren
      eines eventuell zu dem jeweiligen Spiel oder Service gehörigen Forums.
    </p>
    <p>
      9.3.7 Der Nutzer verpflichtet sich, unter keinen Umständen den Account,
      den Spielernamen oder das Kennwort eines anderen Nutzers zu verwenden.
    </p>
    <h3>
      9.4 Pflichten des Nutzers hinsichtlich etwaiger von ihm eingestellten
      Informationen
    </h3>
    <p>
      9.4.1 Der Nutzer hat die Informationen, die er auf der Plattform
      veröffentlicht und anderen Nutzern bereitstellt, sorgfältig auszuwählen.
    </p>
    <p>
      9.4.2 Der Nutzer verpflichtet sich, im Rahmen der Services keine Inhalte
      (beispielsweise Bilder, Videos, Links, Namen, Worte) mit werbenden,
      politischen, religiösen, beleidigenden, belästigenden, gewalttätigen,
      sexistischen, pornografischen oder sonstigen moralisch verwerflichen oder
      anstößigen, insbesondere rassistischen, sowie rechts- oder linksextremen
      Inhalten, Personen oder Darstellungen zu verbreiten. Weiterhin
      verpflichtet sich der Nutzer, keine rechtlich geschützten Begriffe, Namen,
      Bilder, Videos, Musikstücke, Spiele oder andere Materialien zu verwenden.
      Im Zweifelsfall hat der Nutzer von MK gerügte Inhalte unverzüglich zu
      entfernen. MK hat auch das Recht, diese selbst zu entfernen. Der Nutzer
      wird stets jede einschlägige Gesetzes- und Rechtsvorschriften,
      insbesondere zum Jugendschutz, Datenschutz, Schutz des
      Persönlichkeitsrechts, Schutz vor Beleidigung, Urheberrechte, Markenrechte
      usw. beachten.
    </p>
    <p>
      9.4.3 Der Nutzer ist nicht befugt, die Services zu illegalen oder
      unbefugten Zwecken zu nutzen. Insbesondere ist er nicht berechtigt,
      Spielernamen oder E-Mail-Adressen von anderen Nutzern ohne deren
      vorheriges Einverständnis für den Versand von etwaigen unaufgeforderten
      E-Mails, Werbebotschaften oder zu sonstigen gewerblichen oder
      kommerziellen Zwecken zu nutzen.
    </p>
    <p>
      9.4.4 Eine schuldhafte Zuwiderhandlung gegen die vorbezeichneten Pflichten
      berechtigt MK, die vom Nutzer eingestellten Informationen selbst zu
      löschen.
    </p>
    <p>
      9.4.5 Insbesondere ist MK berechtigt, eingestellte Informationen ganz oder
      teilweise zu löschen, wenn konkrete Anhaltspunkte für einen Verstoß gegen
      diese AGB, sowie die Anleitungen und Regeln der jeweiligen Services
      vorliegen oder diese sonst wie rechtswidrig sind. Dies ist beispielsweise
      der Fall für Informationen, die:
    </p>
    <ol>
      <li>
        offenkundig anstößig, rassistisch, fanatisch, gewaltverherrlichend sind;
      </li>
      <li>
        eine andere Person belästigen oder beleidigend, bedrohend, obszön,
        diffamierend oder verleumderisch sind;
      </li>
      <li>
        sexistisch, pornographisch oder anderweitig jugendgefährdender Natur
        sind oder einen Link zu einer nicht jugendfreien Webseite enthalten;
      </li>
      <li>
        falsche oder irreführende Informationen enthalten; illegale
        Verhaltensweisen fördern;
      </li>
      <li>
        eine illegale oder unberechtigte Kopie oder Verbreitung des
        urheberrechtlich geschützten Werks darstellen, z. B. durch
        Bereitstellung von illegalen Computerprogrammen oder Links zu illegalen
        Computerprogrammen, Informationen zur Umgehung von
        Kopierschutzvorrichtungen und illegalen Musikkopien oder Links zu
        illegalen Musikkopien oder auf andere Weise gegen das Urheberrecht
        verstoßen;
      </li>
      <li>
        das Versenden von „Junk-Mails“, „Kettenbriefen“, unerwünschten
        Massennachrichten oder „Spamming“;
      </li>
      <li>
        kriminelle Aktivitäten oder Vorhaben fördern oder Anweisungen zu
        illegalen Aktivitäten enthalten oder dazu anstiften, oder u.a., aber
        nicht ausschließlich, Informationen enthalten zur Herstellung oder zum
        Kauf von Waffen, Kinderpornografie, Betrug, Drogenhandel, Glücksspiel,
        Stalking, Spamming, Verbreitung von Computerviren und anderen
        schädlichen Dateien, Urheberrechtsverletzungen, Patentverletzungen oder
        Diebstahl von Betriebsgeheimnissen;
      </li>
      <li>
        andere Benutzer zur Angabe personenbezogener Daten für kommerzielle oder
        gesetzwidrige Zwecke oder zur Angabe von Login-Daten auffordern;
      </li>
      <li>
        kommerzielle Aktivitäten oder Verkäufe beinhalten, z.B.
        Preisausschreiben, Verlosungen, Tauschgeschäfte, Inserate,
        Schneeballsysteme;
      </li>
      <li>
        eine Abbildung einer anderen Person beinhalten, ohne dass deren
        Zustimmung vorliegt.
      </li>
    </ol>
    <p>
      9.4.6 Ein Anspruch auf Wiederherstellung gelöschter Informationen besteht
      nicht. Darüber hinaus ist MK berechtigt, den Nutzer von der weiteren
      Teilnahme an den betroffenen Services auszuschließen und den Account des
      Nutzers bei Verstoß gegen vorstehende Vorschriften fristlos zu kündigen.
      Die Geltendmachung weiterer Ansprüche durch MK, insbesondere
      Schadensersatzansprüche, bleibt ausdrücklich vorbehalten.
    </p>
    <p>
      9.4.7 Der Nutzer wird MK darüber informieren, falls er Kenntnis von einer
      missbräuchlichen Nutzung der Services durch Dritte bzw. andere Nutzer
      (z.B. Verbreitung und Versendung von Inhalten, die nach Ziff. 9.4.5
      verboten sind) erhält. Zur Sicherstellung eines effektiveren Eingriffs
      wird der Nutzer gebeten, dies in Textform (z.B. per E-Mail) zu tun.
    </p>
    <h3>
      9.5 Haftung des Nutzers für etwaige von ihm eingestellte Informationen
    </h3>
    <p>
      9.5.1 Der Nutzer ist für Texte, Dateien, Bilder, Fotos, Videos, Sounds,
      Musikwerke, urheberrechtliche Werke oder andere Materialien, Informationen
      usw. (nachstehend „eingestellte Informationen“), die er auf der Plattform
      zur Verfügung stellt oder mit anderen Nutzern austauscht, selbst
      verantwortlich. MK macht sich diese Inhalte weder zu eigen noch stimmt sie
      diesen zu.
    </p>
    <p>
      9.5.2 Über die auf der Plattform eingestellten Informationen hat MK keine
      Kontrolle. Eine Prüfung der eingestellten Informationen vor deren
      Veröffentlichung wird durch MK nicht vorgenommen. Sofern MK jedoch
      Kenntnis von rechtswidrigen eingestellten Informationen hat oder erhält,
      wird sie diese unverzüglich löschen.
    </p>
    <p>
      9.5.3 Jegliche Haftung der MK für die eingestellten Inhalte, insbesondere
      für die Richtigkeit, Vollständigkeit oder Zuverlässigkeit von Inhalten,
      Materialien oder Informationen ist ausgeschlossen.
    </p>
    <h3>9.6 Urheberrechte</h3>
    <p>
      9.6.1 Alle Rechte an den vom Nutzer eingestellten Informationen verbleiben
      bei diesem. Durch das Einstellen von Information in die Plattform oder
      deren Spiele bzw. Services gewährt der Nutzer der MK eine nicht
      ausschließliche, entgeltfreie und jederzeit frei widerrufliche Lizenz, um
      diese Inhalte auf der Plattform bzw. den Spieleseiten öffentlich
      darzubieten, öffentlich anzuzeigen, zu reproduzieren und zu verbreiten.
    </p>
    <p>
      9.6.2 Darüber hinaus hat MK keine Nutzungsrechte an den vom Nutzer
      eingestellten Informationen. MK ist nicht berechtigt, eingestellte
      Informationen außerhalb der Plattform, bzw. der jeweiligen Seite der
      Spiele und Services, zu verbreiten.
    </p>
    <p>
      9.6.3 Vom Nutzer auf der Plattform eingestellte Informationen können
      weltweit über das Internet von Dritten eingesehen werden. Der Nutzer
      erklärt mit der Bereitstellung der Informationen hierzu sein
      Einverständnis.
    </p>
    <p>
      9.6.4 Diese Lizenz verliert ihre Gültigkeit, wenn der Nutzer die Inhalte
      von der Plattform und deren Services löscht.
    </p>
    <h2>10. Mängelansprüche</h2>
    <p>
      10.1 MK stellt dem Nutzer den Zugang zu den Spielen und Services in ihrer
      jeweils vorhandenen Fassung zur Verfügung (Ziff. 1.2). Der Nutzer hat
      keinen Anspruch auf Aufrechterhaltung oder Herbeiführung eines bestimmten
      Zustandes/Funktionsumfangs des Spiels oder der Services. Dem Nutzer ist
      bewusst, dass die Spiele und Services – wie jede Software – nie völlig
      fehlerfrei sein können. Als mangelhaft gelten die Spiele bzw. Services
      also nur, wenn ihre Spielbarkeit bzw. Nutzung schwer und nachhaltig
      gestört ist. Vom Nutzer festgestellte Mängel an den Spielen, Services oder
      sonstigen Leistungen oder Lieferungen der MK sollten aussagekräftig
      dokumentiert werden und unter Protokollierung angezeigter Fehlermeldungen
      zu Beweissicherungszwecken in geeigneter Form (z.B. in Textform als E-Mail
      oder über das Kontaktformular) an MK gemeldet werden. Vor Meldung eines
      etwaigen Fehlers wird der Nutzer die Spiel- bzw. Serviceanleitung und ggf.
      andere von MK zur Verfügung gestellten Hilfestellungen zur
      Problembeseitigung (insbesondere Listen mit häufig gestellten Fragen,
      Diskussionsforen zu Problemen) konsultieren. Der Nutzer soll MK bei einer
      möglichen Mängelbeseitigung nach Kräften unterstützen.
    </p>
    <p>
      10.2 Der Nutzer hat die i. S. v. Ziff 10.1 festgestellten und
      dokumentierten Mängel innerhalb einer Frist von 14 Tagen nach Auftreten
      bzw. Kenntnisnahme des Mangels gegenüber MK zu rügen.
    </p>
    <p>
      10.3 Von der Gewährleistung ausgeschlossen sind grundsätzlich solche
      Fehler, die durch äußere Einflüsse, zu vertretende Bedienungsfehler des
      Nutzers, höhere Gewalt oder nicht von MK durchgeführte Änderungen oder
      sonstige Manipulationen entstehen.
    </p>
    <p>10.4 Garantien im Rechtssinne werden von MK nicht übernommen.</p>
    <h2>11. Haftungsbeschränkung</h2>
    <p>
      11.1 Der Nutzer haftet bei der von ihm zu vertretenden Verletzung von
      Rechten Dritter gegenüber diesen selbst und unmittelbar. Der Nutzer
      verpflichtet sich, MK alle Schäden zu ersetzen, die aus der Nichtbeachtung
      der sich aus diesen AGB ergebenden Pflichten entstehen. Der Nutzer stellt
      MK von sämtlichen Ansprüchen frei, die andere Nutzer oder sonstige Dritte
      gegenüber MK geltend machen, wegen Verletzung ihrer Rechte durch von dem
      Nutzer eingestellte Inhalte oder wegen der Verletzung sonstiger Pflichten.
      Der Nutzer übernimmt hierbei auch die Kosten der notwendigen
      Rechtsverteidigung von MK einschließlich sämtlicher Gerichts- und
      Anwaltskosten. Dies gilt nicht, soweit die Rechtsverletzung vom Nutzer
      nicht zu vertreten ist.
    </p>
    <p>
      11.2 Soweit MK ihre Leistungen entgeltfrei erbringt, haftet MK in keinem
      Fall für andere als grob fahrlässig oder vorsätzlich verursachte Schäden.
      Dies gilt jedoch nicht für die Haftung bei Verletzung von Leben, Körper
      und Gesundheit oder im Falle der Übernahme einer Garantie durch MK.
    </p>
    <p>
      11.3 Soweit MK für ihre Leistungen ein Entgelt fordert, haftet MK bei
      Vorsatz und grober Fahrlässigkeit uneingeschränkt. Bei leichter
      Fahrlässigkeit haftet MK nur im Falle der Verletzung von wesentlichen
      Vertragspflichten oder der Verletzung einer Garantie. Unter wesentlichen
      Vertragspflichten sind solche Pflichten zu verstehen, die die
      ordnungsgemäße Durchführung des Vertrages erst ermöglichen und auf deren
      Erfüllung der Nutzer vertrauen darf. Vorstehende Haftungsbeschränkungen
      gelten nicht für die Haftung bei Verletzung von Leben, Körper und
      Gesundheit oder im Falle der Übernahme einer Garantie durch MK. Die
      Haftung von MK nach dem Produkthaftungsgesetz oder im Anwendungsbereich
      des § 44a Telekommunikationsgesetz bleibt unberührt.
    </p>
    <p>
      11.4 Die Ersatzpflicht ist bei leicht fahrlässiger Verletzung von
      wesentlichen Vertragspflichten i. S. d. Ziff. 12.3. Satz 3 auf den bei
      Vertragsschluss vorhersehbaren und vertragstypischen Schaden beschränkt.
    </p>
    <p>
      11.5 Vorstehende Haftungsausschlüsse bzw. -beschränkungen gelten auch im
      Hinblick auf die Haftung der Angestellten, Arbeitnehmer, Mitarbeiter,
      Vertreter und Erfüllungsgehilfen der MK insbesondere zugunsten der
      Anteilseigner, Mitarbeiter, Vertreter, Organe und deren Mitgliedern
      betreffend ihrer persönlichen Haftung.
    </p>
    <p>
      11.6 MK haftet für Beratung nur, soweit die Fragestellung den Inhalt des
      Angebots betroffen hat.
    </p>
    <p>
      11.7 MK distanziert sich ausdrücklich von den Inhalten sämtlicher Seiten,
      auf die direkte oder indirekte Verweise (sog. „Links“) aus dem Angebot der
      MK bestehen. MK übernimmt für diese Inhalte und Seiten keinerlei Haftung.
      Für die Inhalte dieser Seiten sind die Anbieter der jeweiligen Seiten
      selbst verantwortlich.
    </p>
    <h2>12. Gewinne</h2>
    <p>
      12.1 Der Nutzer wird nach Feststellung des Gewinnfalles eines Preises
      durch ein Gewinnspiel von MK in Kenntnis darüber gesetzt. Um den Sachpreis
      zu erhalten muss der Nutzer MK eine zustellfähige Adresse mitteilen, an
      welche der Gewinn gesendet werden soll. Bei Gewinnen, die jugendgefährdend
      sind, ist der Nutzer verpflichtet, MK eine Kopie des Personalausweises
      bzw. Reisepasses zu übermitteln, um eine Altersüberprüfung vorzunehmen.
      Nach Übermittlung der vorgenannten Daten versendet MK innerhalb von 14
      Tagen den Gewinn per Post bzw. einen Versanddienstleister an den Nutzer.
      MK ist berechtigt, Nutzern, die keine Adresse in Deutschland, Österreich
      oder Spanien angeben, die dem User angebotenen „Alternativchips“
      gutzuschreiben.
    </p>
    <p>
      Von Gewinnen ausgeschlossen sind MitarbeiterInnen der Schmidtgruppe und
      deren verbundenen Unternehmen sowie MitarbeiterInnen von
      Kooperationspartnern und deren Angehörige.
    </p>
    <p>
      12.2 Für den Fall, dass der Gewinner MK die erforderlichen Daten nicht
      mitteilt oder eine Zustellung nicht erfolgen kann, oder eine Zustellung
      aufgrund einer falschen Adresse zurückkommt, verfällt jedweder
      Gewinnanspruch, unabhängig davon, ob es sich um einen Sachpreis handelt
      der versendet werden kann oder nicht, nach 4 Wochen.
    </p>
    <h2>13. Online-Streitbeilegung</h2>
    <p>
      13.1. Die Europäische Kommission stellt eine Plattform für die
      außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, die unter
      <a href="http://www.ec.europa.eu/consumers/odr"
        >http://www.ec.europa.eu/consumers/odr</a
      >
      aufrufbar ist.
    </p>
    <p>
      13.2. Die E-Mail-Adresse der MK ist dem Impressum zu entnehmen. Wir sind
      weder verpflichtet noch bereit, an dem Streitschlichtungsverfahren
      teilzunehmen.
    </p>
    <h2>14. Schlussbestimmungen</h2>
    <p>
      14.1 Die Nutzer sind nicht berechtigt, ihre Rechte aus diesem
      Vertragsverhältnis auf Dritte zu übertragen. Etwas anderes gilt nur, wenn
      MK dem vorher schriftlich oder in Textform zustimmt. MK ist berechtigt,
      die sich aus diesem Vertragsverhältnis ergebenden Rechte und Pflichten auf
      einen Dritten zu übertragen. Der Nutzer wird mindestens vier Wochen vorher
      durch Ankündigung auf der Plattform und / oder E-Mail-Benachrichtigung von
      der Übertragung in Kenntnis gesetzt. Der Nutzer hat die Möglichkeit, die
      Nutzung der Spiele und / oder Services ohne Einhaltung von Fristen zu
      kündigen. Auf dem Account zu diesem Zeitpunkt vorhandene virtuelle Währung
      wird dem Nutzer dabei erstattet. Ziff. 8.3.5 gilt sinngemäß.
    </p>
    <p>
      14.2 Für auf Grundlage dieser Nutzungsbedingungen abgeschlossene Verträge
      und damit in Zusammenhang stehende Ansprüche gilt ausschließlich das Recht
      der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. Hat der
      Nutzer den Vertrag als Verbraucher geschlossen, sind darüber hinaus die
      zwingenden Verbraucherschutzbestimmungen anwendbar, die in dem Staat
      gelten, in dem der Nutzer seinen gewöhnlichen Aufenthalt hat, sofern diese
      dem Nutzer einen weitergehenden Schutz gewähren.
    </p>
    <p>
      14.3 Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder werden,
      so berührt dies die Wirksamkeit der übrigen Bestimmungen nicht.
    </p>
    <p>
      Coesfeld, den 31.10.2020 <br />
      Meine Krone GmbH <br />
      Druffels Weg 1 <br />48653 Coesfeld<br />
      E-Mail: <a href="mailto:kontakt@sgservice.de">kontakt@sgservice.de</a
      ><br />
      Telefon: +49 2541/94 06 0<br />
      Telefax: +49 2541/94 06 20<br />
      Handelsregister: HRB 17958 (Amstgericht Coesfeld) <br />
      USt-IdNr.: 322521921<br />
      Geschäftsführer: Arne Schmidt, Linus Menden<br />
    </p>
  </div>
</template>

<script>
export default {
  name: "TacTextDe",
  computed: {
    mainUrl() {
      return process.env.VUE_APP_HOST;
    },
  },
};
</script>
