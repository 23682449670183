<template>
  <div>
    <h1>Impressum</h1>

    <div>
      <span class="bold">Meine Krone GmbH</span><br />
      Druffels Weg 1<br />
      48653 Coesfeld, D<br />
    </div>
    <div class="mt-3">
      <span class="text-uppercase bold"
        >Alleinvertretungsberechtigter Geschäftsführer:</span
      ><br />
      Arne Schmidt<br />
    </div>
    <div class="mt-3">
      <span class="text-uppercase bold"> Angaben zur Kontaktaufnahme</span
      ><br />
      Tel.: 0049 (0) 2541 94 06 0<br />
      Fax: 0049 (0) 2541 94 06 20 <br />
      E-Mail:
      <a href="mailto:support@kronesupport.zendesk.com"
        >support@kronesupport.zendesk.com</a
      ><br />
      Internet: <a href="www.kleinekrone.de">www.kleinekrone.de</a> <br />
    </div>
    <div class="mt-3">
      <span class="text-uppercase bold"> Register und Registernummer</span
      ><br />
      Amtsgericht Coesfeld: HRB 17958<br />
    </div>
    <div class="mt-3">
      <span class="text-uppercase bold">
        Umsatzsteuer-Identifikationsnummer</span
      ><br />
      DE322521921
    </div>
  </div>
</template>

<script>
export default {
  name: "ImprintTextDE",
};
</script>
