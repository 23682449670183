<template>
  <TabbedPage id="legal-page">
    <template v-slot:nav-tabs>
      <NavTab
        v-for="tab in contentTabs"
        :key="tab.title"
        :href="tab.path"
        :label="$t(tab.title)"
    /></template>
    <template v-slot:top-tabbed-content>
      <b-row id="nav-mobile" class="mb-3" no-gutters
        ><b-nav class="mx-auto">
          <b-nav-item :to="{ path: '/tac' }">{{ $t("legal.tac") }}</b-nav-item
          ><b-nav-item :to="{ path: '/privacy' }" class="mx-3">{{
            $t("legal.privacy")
          }}</b-nav-item
          ><b-nav-item :to="{ path: '/imprint' }">{{
            $t("legal.imprint")
          }}</b-nav-item></b-nav
        ></b-row
      ></template
    >
    <template v-slot:main-content>
      <router-view @back="handleBackClick"></router-view
    ></template>
  </TabbedPage>
</template>

<script>
import NavTab from "@/components/base/nav-tab.vue";
import TabbedPage from "@/views/TabbedPage.vue";
import checkLocale from "@/mixins/checkLocale.js";

export default {
  name: "LegalPage",
  components: {
    NavTab,
    TabbedPage,
  },
  mixins: [checkLocale],
  data() {
    return {
      contentTabs: [
        {
          title: "legal.tac",
          path: "/tac",
          name: "terms-and-conditions",
        },
        {
          title: "legal.imprint",
          path: "/imprint",
          name: "imprint",
        },
        {
          title: "legal.privacy",
          path: "/privacy",
          name: "privacy",
        },
      ],
    };
  },
  mounted() {
    this.$store.commit("setIsPageLoading", false);
  },

  methods: {
    handleBackClick() {
      if (this.$store.getters["auth/isLoggedIn"]) {
        this.$router.back();
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="scss">
#legal-page {
  @media (max-width: 800px) {
    .nav-container {
      display: none;
    }
  }
  .content-binder {
    padding: 0;
    max-width: 100%;
  }
  h3 {
    color: $light-blue;
    text-transform: uppercase;
    font-family: Ubuntu;
  }
  h4 {
    color: $light-blue;
    font-family: Ubuntu;
  }
  a {
    color: $hightlight-yellow;
    font-weight: bold;
  }
  .bold-outline {
    font-weight: bold;
    outline: 2px solid $hightlight-yellow;
    padding-left: 5px;
  }

  @media (max-width: 556px) {
    padding: 0;
  }

  #nav-mobile {
    display: none;
    @media (max-width: 800px) {
      display: flex;
    }
    .nav {
      flex-direction: column;
      align-items: center;
      .nav-item {
        .router-link-active {
          text-shadow: 0 0 8px white 0 0 4px white 0 0 4px $light-blue;
        }
      }
    }
    a {
      color: white;
      text-shadow: 0 2px 2px black;
      text-transform: uppercase;
      font-size: 16px;
      &:hover {
        text-decoration: none;
        filter: $hover-shadow;
      }
    }
  }

  .content-row {
    min-height: 300px;
    color: white;
    background-color: $dark-blue-06;
    border-radius: 32px;
    box-shadow: 0 0 16px white, 0 0 8px white, 0 0 4px $light-blue;
  }
}
</style>
